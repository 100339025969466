import React from 'react'

const Terms = () => {

    document.title = 'Terms and Conditions - Taojaa | Smart business management tool, for SMEs and local businesses.'

    return (
        <div className='container mb-5'>
            <div className='row'>
                <div className='col-lg-3 desktop relative'>
                    <ul className="vn-guides-sidebar border-right" id="vn-guides-sidebar">
                        <li className="text-muted vn-item active mb-3"><a className="vc-click" href="#intro">Introduction</a></li>
                        <li className="text-muted vn-item mb-3"><a className="vc-click" href="#limited-license">Limited License</a></li>
                        <li className="text-muted vn-item mb-3"><a className="vc-click" href="#usage-permissions">Usage Permissions</a></li>
                        <li className="text-muted vn-item mb-3"><a className="vc-click" href="#copyright-policy">Copyright Policy</a></li>
                        <li className="text-muted vn-item mb-3"><a className="vc-click" href="#intellectual-resources">Intellectual Resources</a></li>
                        <li className="text-muted vn-item mb-3">
                            <a className="vc-click" href="#violation-of-terms-and-conditionss">
                                Violation of Terms and Conditions
                            </a>
                        </li>
                        <li className="text-muted vn-item mb-3"><a className="vc-click" href="#applicable-law">Applicable Law</a></li>
                        <li className="text-muted vn-item mb-3"><a className="vc-click" href="#indemnity">Indemnity</a></li>
                        <li className="text-muted vn-item mb-3"><a className="vc-click" href="#company-operations">Company Operations</a></li>
                        <li className="text-muted vn-item mb-3"><a className="vc-click" href="#product-description">Product Description</a></li>
                        <li className="text-muted vn-item mb-3"><a className="vc-click" href="#purchases-and-payment">Purchases & Payment</a></li>

                    </ul>
                </div>
                <div className="col-lg-9">
                    <div id="intro" className="vn-guides pt-5">
                        <h1 className="">Introduction To Taojaa Terms & Conditions</h1>

                        <p className="vn-guides-desc mb-2">
                            Taojaa is a business management and e-commerce platform,
                            with the objectives of providing SMEs with solutions to connect with customers from anywhere seamlessly and build trust while selling online.
                            The following are the Terms of Use (the “Terms and Conditions”) that govern, use of the Site by all visitors
                            and users of the Website, whether browsing or making a purchase. Please note that your
                            use of the Website service constitutes your unconditional agreement to follow and be
                            bound by these terms and conditions and all applicable laws and regulations governing
                            the use of the Site. We reserve the right to change the Terms and Conditions at any time.
                            Any such changes will take effect when posted on the Website. All updates and modifications
                            to these Terms and Conditions shall be noted on the bottom of the Terms and Conditions herein.
                            Any violation of these Terms of use, shall permit Taojaa to terminate your use of the Site,
                            barn you from future use of the Site, and/or take appropriate legal action against you.
                        </p>
                    </div>

                    <div id="limited-license" className="vn-guides mt-5 pt-5">
                        <h1 className="">Limited License</h1>

                        <p className="mb-2 vn-guides-desc">
                            You are granted a limited, non-exclusive, revocable and non-transferable license to
                            utilize and access the Site pursuant to the requirements and restrictions herein of
                            these Terms and Conditions and any amendments and/or revisions that are hereby made
                            to these Terms and Conditions, regardless of whether the user has agreed to the Terms
                            and Conditions before the amendments and/or revisions. It is the responsibility of and
                            the, user hereby assumes such responsibility of checking the Terms and Conditions prior
                            to each use of the Website. Including, but not limited to Taojaa reserving the right
                            to change, suspend, or discontinue any aspect of the Site at any time, without any
                            obligation or liability to users of the Website. Taojaa may also, without notice or
                            liability, impose limits on certain features and services or restrict your access to all
                            or portions of the Site. You shall have no rights to the proprietary software and related
                            documentation, if any, provided to you in order to access the Site. Except as provided
                            in the Terms and Condition, you shall have no right to directly or indirectly, own, use,
                            loan, sell, rent, lease, license, sublicense, assign, copy, translate, modify, adapt,
                            improve, or create any new or derivative works from, or display, distribute, perform, or
                            in any way exploit the Site, or any of its contents (including software) in whole or in part.
                        </p>
                    </div>

                    <div id="usage-permissions" className="vn-guides mt-5 pt-5">
                        <h1 className="">Usage Permissions</h1>
                        <p className="vn-guides-desc mb-2">
                            You agree that you are only authorized to visit, view and to retain a copy of pages of
                            this Site for your own personal use, and that you shall not duplicate, download, publish,
                            modify or otherwise distribute the material on this Site for any purpose other than for
                            personal use, unless otherwise specifically authorized by Taojaa to do so. You also
                            agree not to deep-link to the site for any purpose, unless specifically authorized by
                            Taojaa to do so. The content and software on this Site is the exclusive property of Taojaa
                        </p>
                    </div>

                    <div id="copyright-policy" className="vn-guides mt-5 pt-5">
                        <h1 className="">Copyright Policy</h1>
                        <p className="mb-2 vn-guides-desc">
                            The contents of the pages, contained within the sites, Taojaa, not limited to pictures and photographs, descriptions and written text, designs, logos, trademarks, and other materials, are the copyright trademark or registered trademark of Taojaa and or the respective owners of such content, including technology providers. All rights are reserved. Taojaa may terminate the privileges of any user who uses this Site to unlawfully transmit copyrighted material without a license, express consent, valid defense or fair use exemption to do so. If you submit information to this Site, you warrant that the information does not infringe the copyrights or other rights of third parties. Taojaa also reserves all legal rights to any and all legal recourse that may be required as a result of the violation of this provision.
                        </p>
                        <p className="mb-2 vn-guides-desc">
                            The copying, printing, downloading of any material from this site for personal or commercial use, other than for placing an order with Taojaa is strictly prohibited, and all rights are reserved, with regards to any available or required recourse by Taojaa.
                        </p>
                    </div>

                    <div id="intellectual-resources" className="vn-guides mt-5 pt-5">
                        <h1 className="">Intellectual Resources</h1>
                        <p className="vn-guides-desc mb-1">
                            Taojaa is not responsible for the content, quality or accuracy of data provided by users, compilations of such data, text, information and other materials made available to users through Taojaa system. The Online Materials are Taojaa intellectual property, and are protected by C.A.C. Corporate Affairs Commission of Nigeria. International Intellectual property laws, as well as the Intellectual property laws of any country in which Taojaa Intellectual property is being transmitted, disseminated, or misappropriated. The Online Materials may not be copied or redistributed either in whole or in part without prior written consent from Taojaa, except as expressly and specifically permitted under these Terms and Conditions.
                        </p>
                        <p className="vn-guides-desc mb-2">
                            The Online Materials are and will remain the exclusive property of Taojaa. All rights, titles and interests in and to the Online Materials will be and remain vested solely in Taojaa. Under no circumstances will any user or visitor of this site have any right, directly or indirectly, to own, use, copy, loan, sell, rent, lease, license, sublicense, redistribute, assign or otherwise convey the Online Materials, or any rights thereto, except as expressly and specifically provided in the Terms and Conditions. Nothing in these Terms of Use will convey to you any right, title or interest, except that of a license with the express rights and subject to all limitations herein. Nothing in these Terms of Use grants you the right, directly or indirectly, to use the Online Materials to create a product for resale or to use the Online Materials in any way that competes with Taojaa.
                        </p>
                    </div>

                    <div id="violation-of-terms-and-conditions" className="vn-guides mt-5 pt-5">
                        <h1 className="">Violation of Terms and Conditions</h1>
                        <p className="mb-2 vn-guides-desc">
                            You understand and agree that in Taojaa sole discretion, and without prior notice, Taojaa may terminate your access to the Site, or exercise any other remedy available and remove any unauthorized user information, if Taojaa believes that the information you have, provided, has violated or is inconsistent with these Terms and Conditions, or violates the rights of Taojaa, or any third party, or violates the law. You agree that monetary damages may not provide a sufficient remedy to Taojaa for violations of these Terms and Conditions and you consent to injunctive or other equitable relief as may be required and allowed by law for such violations. Taojaa may release user information about you if required by law.
                        </p>
                    </div>

                    <div id="applicable-law" className="vn-guides mt-5 pt-5">
                        <h1 className="">Applicable Law</h1>
                        <p className="mb-2 vn-guides-desc">
                            The Laws of the Republic of Nigeria, shall govern the use of the Site and the Terms of Use, without regards to conflict of laws and principals. All disputes arising in connection therewith shall be heard only by a court of competent jurisdiction in Nigeria
                        </p>
                    </div>

                    <div id="indemnity" className="vn-guides mt-5 pt-5">
                        <h1 className="">Indemnity</h1>
                        <p className="mb-2 vn-guides-desc">
                            Taojaa makes no warranty or representation of any kind, either express or implied, including but not limited to warranty of title or non-infringement, implied warranty of merchantability, fitness of a product for a particular purpose, or other violation of rights in relation to the accuracy, validity, reliability, or content of any products and/or the site itself. By using this site, all users and visitors agree to indemnify and hold Taojaa, its subsidiaries, affiliates, officers, agents and other partners and employees, harmless from any loss, liability, claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of or relating to your use of the Site, including also your use of the Site to provide a link to another site or to upload content or other information to the Site, or your breach of the Terms and Conditions.
                        </p>
                    </div>

                    <div id="company-operations" className="vn-guides mt-5 pt-5">
                        <h1 className="">Company Operations</h1>
                        <p className="mb-2 vn-guides-desc">
                            Taojaa controls this Site through its headquarters and domicile, located in Nigeria. Taojaa makes no representation that this Site is appropriate for use in any other locations or jurisdictions. If you use this Site from other locations you are responsible for ensuring compliance with any such local laws, which may differ or impact the use and/or operation of this site. You may not use, export or re-export any materials from this Site in violation of any applicable laws or regulations, including, but not limited to any Nigeria, export laws and regulations.
                        </p>
                    </div>

                    <div id="product-description" className="vn-guides mt-5 pt-5">
                        <h1 className="">Product Description</h1>
                        <p className="mb-2 vn-guides-desc">
                            Taojaa make no warranty as to how the products sold on this site are depicted via the images and/or descriptions on this Site or otherwise. Reliance on any such depiction is not the responsibility of Taojaa, and as such, Taojaa shall be held harmless thereto. Taojaa return policy is fully and accurately outlined pursuant to the clauses contained within these Terms and Conditions.
                        </p>
                    </div>

                    <div id="purchases-and-payment" className="vn-guides mt-5 pt-5">
                        <h1 className="">Purchases and Payment</h1>
                        <p className="mb-2 vn-guides-desc">
                            Taojaa accepts online payment, validates delivery, or via this Site by debit cards or credit cards. All purchases, will be billed in Nigerian Naira (NGN), United States Dollars (USD) and CryptoCurrencies such as Bitcoin, Ethereum and the likes
                            for products and services provided. All online purchases are also governed by the terms and conditions of the respective third party merchant provider who provides the products that are seen on the Site.
                        </p>
                        <p className="mb-2 vn-guides-desc">
                            Taojaa holds out no promises or warranties with regards to the advertised prices of any products sold on this site or otherwise by Taojaa. All product prices are subject to change, and only become final once payment is made by the consumer.
                        </p>
                        <p className="mb-2 vn-guides-desc">
                            By making payment for our products or services on our Website, all details that you, as the consumer, are prompted and required to submit will be provided directly to our payment provider via a secured connection. Taojaa holds out no promises or warranties with regards to any breaches of the secured connection that were not to Taojaa up’s knowledge.
                        </p>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Terms