import Modal from '../Modal';
import React, { useState, useEffect } from 'react'
import { ReactComponent as Forgotpasswordsvg } from '../assets/Forgotpasswordsvg.svg';
import { ReactComponent as ForgotPasswordMobileImage } from '../assets/ForgotPasswordMobileImage.svg';
import { ReactComponent as Check } from '../assets/check.svg';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { FcCheckmark } from 'react-icons/fc';
import Logo from '../Shared/Logo';
import axios from 'axios';
import ValidateSubmit from 'wearslot-dev-utils';
import { messageHandler } from '../../bundles/app';
import Error from '../assets/error.png'
import { Link, useSearchParams } from 'react-router-dom';

export const ModalBody = () => {
    return (
        <div className='d-flex flex-column align-items-center justify-content-center tj-body-forget mt-2 position-relative'>
            <Check className='tj-icon4' />
            <div className='mb-3'>
                <span>Password has been updated successfully</span>
            </div>
            <div className=''>
                <Link to="/auth/login" className="text-primary">Continue to Login</Link>
            </div>
        </div>
    )
}

const ResetPassword = () => {

    document.title = 'Reset Password - Taojaa';

    const [searchParams] = useSearchParams()

    const [loading, setLoading] = useState(false);
    const [verify, setVerify] = useState(false)
    const [show, setShow] = useState(false)
    const [visibility, setVisibility] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [user, setUser] = useState({
        code: '',
        password: '',
        confirm_password: ''
    })

    const reset_code = searchParams.get('reset_id');

    useEffect(() => {
        verifyPassword()
    }, [])

    const verifyPassword = async () => {
        setIsLoading(true);

        try {

            let response = await axios.get(`/account/verify/reset?code=${reset_code}`)
            if (response.data.success) {
                return setVerify(true)
            }

        } catch (error) { }

        setIsLoading(false);
    }



    const handleInput = e => {
        const field = e.target.name
        const value = e.target.value

        setUser({ ...user, [field]: value })
    }

    const handleSubmit = (e) => {
        if (ValidateSubmit(false, true)) {

            e.preventDefault()
            setLoading(true);

            axios.post('/account/change-password', { ...user, code: reset_code }).then(response => {
                if (response.data.success) {
                    messageHandler('success', response.data.message)
                    setShow(true)
                    setIsLoading(false);
                    setLoading(false)
                }
            }).catch(error => {
                setLoading(false)
                if (error.response) {
                    messageHandler("error", error.response.data.message);
                } else {
                    messageHandler("error", 'Unable to process request. Please try again');
                }
            })
        }
    }

    return (
        <>

            {
                (verify) ? (
                    <div className='col-md-12'>
                        <div className='tj-form-response'>
                            <p id='tj-resp-Message'></p>
                        </div>
                        <div className='row tj-everything'>
                            <div className='col-md-6'>
                                <Logo />
                                <div className='d-flex justify-content-center mt-4'>
                                    <div className='tj-box'>
                                        <div className='mobile mb-2 text-center-mobile'>
                                            <ForgotPasswordMobileImage />
                                        </div>
                                        <div className='text-center-mobile'>
                                            <h1 className='mb-1'>Reset Password</h1>
                                            <h2>Make sure your password is strong and secure</h2>
                                        </div>
                                        <form className='tj-form mb-3 position-relative'>
                                            <div className='form-group'>
                                                <label for="Password" className="form-label">Password</label>
                                                <input
                                                    type={visibility ? 'text' : 'password'}
                                                    value={user.password}
                                                    name='password'
                                                    onChange={e => handleInput(e)}
                                                    validatefield="true"
                                                    validationoutput="#passworderror"
                                                    validationmessage="Password cannot be empty!*"
                                                    className="form-control mb-3"
                                                    id="Password"
                                                    placeholder='password' />
                                                {visibility
                                                    ? <AiOutlineEye className='tj-icon3' onClick={() => setVisibility(false)} />
                                                    : <AiOutlineEyeInvisible className='tj-icon3' onClick={() => setVisibility(true)} />
                                                }
                                                <span id='passworderror'></span>
                                            </div>
                                            <div className='form-group'>
                                                <label for="ConfirmPassword" className="form-label">Confirm Password</label>
                                                <input
                                                    type={visibility ? 'text' : 'password'}
                                                    value={user.confirm_password}
                                                    name='confirm_password'
                                                    onChange={e => handleInput(e)}
                                                    validatefield="true"
                                                    validationoutput="#passworderror"
                                                    validationmessage="Password cannot be empty!*"
                                                    className="form-control"
                                                    id="ConfirmPassword"
                                                    placeholder='password' />
                                                {visibility
                                                    ? <AiOutlineEye className='tj-icon1' onClick={() => setVisibility(false)} />
                                                    : <AiOutlineEyeInvisible className='tj-icon1' onClick={() => setVisibility(true)} />
                                                }
                                                <span id='passworderror'></span>
                                            </div>
                                        </form>
                                        <button className={`tj-box1 mt-4 ${loading ? 'button-disabled' : ''}`} disabled={loading} onClick={handleSubmit}>
                                            {loading ? <lord-icon
                                                src="https://cdn.lordicon.com/ymrqtsej.json"
                                                trigger="loop"
                                                colors="primary:#ffffff"
                                                style={{ width: "50px", height: "50px" }}>
                                            </lord-icon> : 'Proceed'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <Forgotpasswordsvg className='tj-right' />
                            </div>
                            <Modal
                                show={show}
                                onClose={() => setShow(false)}
                                icon={<FcCheckmark className='tj-icon-check' />}
                                body={<ModalBody />}
                            />
                        </div >
                    </div>
                ) : (
                    <section style={{ padding: '30px 0' }}>
                        <div className='container-lg'>
                            <div className='text-center  justify-content-center align-items-center'>
                                {(isLoading) ? null : (
                                    <div>
                                        <h1 className='display-1'>This Email is not verified!</h1>
                                        <p className='display-5 text-muted'>Please verify your email</p>
                                        <img src={Error} alt='Error' style={{ height: '25rem' }} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                )
            }

        </>

    )
}

export default ResetPassword