import React from 'react'
import { ReactComponent as Loginn } from '../assets/Loginn.svg';
import Logo from '../Shared/Logo';
import { BsCheckAll } from 'react-icons/bs';
import { setCookie } from 'wearslot-dev-utils';

export const getContinueLink = (onboarding = false) => {
    if (localStorage.getItem('selectedPlan')) {
        var planName = localStorage.getItem('selectedPlan');
        localStorage.clear();
        return process.env.REACT_APP_STORE_MANAGER_URL + `/subscribe/${planName}/plans${onboarding === true ? "?onboarding=true" : ''}`
    }

    return process.env.REACT_APP_STORE_MANAGER_URL + `${onboarding === true ? "?onboarding=true" : ''}`;
}

const Success = () => {

    document.title = 'Business Setup - Taojaa';

    const token = localStorage.getItem('token');
    setCookie(process.env.REACT_APP_COOKIE_NAME, token, 100, 'taojaa.com');

    return (
        <div className='col-md-12'>
            <div className='row tj-everything'>
                <div className='col-md-6'>
                    <Logo />
                    <div className='d-flex justify-content-center mt-5 pt-5'>
                        <div className='tj-box'>
                            <div className='tj-circle text-center' style={{ backgroundColor: '#d1e7dd' }}>
                                <BsCheckAll size={100} className='text-success mt-2' />
                            </div>
                            <h1 className='d-flex justify-content-center mt-3'>Your account is all set!</h1>
                            <button className='tj-box1' onClick={() => window.location = getContinueLink(true)}>
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 desktop'>
                    <Loginn className='tj-right' />
                </div>
            </div >
        </div>
    )
}

export default Success;