import React from 'react'
import UniqueCard from '../../components/unique/Card'
import { Col, Container, Row } from 'react-bootstrap'
import imag2 from '../../components/assets/pexels-mart-page1-2.png'
import img3 from '../../components/assets/pexels-mart-page1-3.png'
import img4 from '../../components/assets/Frame-page1-5.png'
import img5 from '../../components/assets/image28-page1-6.png'
import CardText from '../../components/unique/CardText'
import CardImage from '../../components/unique/CardImage'
import img8 from '../../components/assets/pexels-mart-page-1.png'
import img9 from '../../components/assets/pexels-mart-page1-4.png'
import Card3 from '../../components/unique/Card3'
import image2 from '../../components/assets/happy-young-african-ladies-hero1.png'
import img2b from '../../components/assets/happy-young-african-ladies-hero1-mobile.png'
import floatImage from '../../components/assets/image24.svg'

export default function UniquePage() {
  return (
    <>
      <Card3
        title={`Grow Your Online Sales with Taojaa.`}
        desp={`Enjoy a seamless selling experience across all your preferred
            channels. Taojaa brings together your products, inventory, and
            orders into one centralized system. making it easy to manage, no
            matter where the sale happens`}
        mainImg2={img2b}
        mainImg={image2}
        floatingImg={floatImage}
      />

      <Container className='g2 mx-auto'>
        <Row className='align-items-center '>
          <section
            className='box-section align-items-center'
          >
            <Row className='align-items-center '>
              <Col md={6} sm={12} data-aos='fade-up' data-aos-duration='1000'>
                <CardImage imgSrc={img8} />
              </Col>
              <Col className='order-md-first' md={6} sm={12} data-aos='fade-up' data-aos-duration='1000'>
                <CardText
                  btnUrl={`Get Started`}
                  desc={` Keep your product listings and inventory centralized, making it simple to manage across different platforms.`}
                  title={`Centralized Inventory Management`}
                />
              </Col>
            </Row>
          </section>
          <section
            className='box-section align-items-center'
          >
            <Row>
              <Col lg={6} className='mb-3' data-aos="fade-up" data-aos-duration="1000">
                <UniqueCard
                  btnUrl={`Get Started`}
                  desc={`Taojaa provides easy integration with popular sales channels, allowing you to expand your reach effortlessly.`}
                  title={`Multi-Sales Channel Integration`}
                  imgSrc={imag2}
                />
              </Col>
              <Col lg={6} className='mb-3' data-aos="fade-up" data-aos-duration="1000">
                <UniqueCard
                  btnUrl={`Get Started`}
                  desc={`Taojaa ensures that your orders are synchronized in real-time across all connected channels, preventing overselling and streamlining fulfillment.`}
                  title={`Real-time Order Sync`}
                  imgSrc={img3}
                />
              </Col>
            </Row>
          </section>
          <section
            className='box-section align-items-center'
          >
            <Row className='align-items-center '>
              <Col lg={6} data-aos="fade-up" data-aos-duration="1000">
                <CardImage imgSrc={img9} />
              </Col>
              <Col className='order1' lg={6} data-aos="fade-up" data-aos-duration="1000">
                <CardText
                  btnUrl={`Get Started`}
                  desc={` Taojaa's intuitive interface makes managing multiple channels easy, even for those new to multichannel selling.`}
                  title={`User-friendly Interface`}
                />
              </Col>
            </Row>
          </section>
          <section
            className=' box-section align-items-center'
          >
            <Row>
              <Col lg={4} className='mb-3' data-aos="fade-up" data-aos-duration="1000">
                <UniqueCard
                  btnUrl={`Get Started`}
                  desc={`Taojaa automates the order processing workflow, reducing manual tasks and enhancing efficiency as you sell across diverse channels.`}
                  title={`Automated Order Processing`}
                  imgSrc={img4}
                />
              </Col>
              <Col lg={4} className='mb-3' data-aos="fade-up" data-aos-duration="1000">
                <UniqueCard
                  btnUrl={`Get Started`}
                  desc={`Tailor your storefronts for each sales channel, ensuring a cohesive brand identity while catering to the unique requirements of each platform`}
                  title={`Customizable Storefronts`}
                  imgSrc={img5}
                />
              </Col>
              <Col lg={4} className='mb-3' data-aos="fade-up" data-aos-duration="1000">
                <UniqueCard
                  btnUrl={`Get Started`}
                  desc={`Embrace global transactions with multi-currency payments. Accept payments in different currencies, providing flexibility to your international customers.`}
                  title={`Multi Currency Payments`}
                  imgSrc={imag2}
                />
              </Col>
            </Row>
          </section>
        </Row>
      </Container>
    </>
  )
}
