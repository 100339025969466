import React from 'react'

import ProductInventoryMenu from "../../../../assets/product-inventory.png";
import ProductDropdownMenu from "../../../../assets/product-dropdown.png";
import AddProductButton from "../../../../assets/add-product-button.png";
import ProductTitle from "../../../../assets/ProductTitle.png";
import ProductDescription from "../../../../assets/ProductDescription.png";
import ProductImages from "../../../../assets/ProductImages.png";
import GeneralDetails from "../../../../assets/GeneralDetails.png";
import MetaData from "../../../../assets/MetaData.png";
import AddVariationButton from "../../../../assets/add-variation-button.png";
import ProductSettings from "../../../../assets/ProductSettings.png";
import CareInformation from "../../../../assets/CareInformation.png";
import SaveButton from "../../../../assets/SaveButton.png";
import Help from '../Help';


const HowToAddProducts = () => {

    return (
        <div className='container mb-5'>
            <div className='row'>
                <div className='col-lg-3 desktop relative'>
                    <ul className="vn-guides-sidebar border-right" id="vn-guides-sidebar">
                        <li className="text-muted vn-item active mb-3 fw-bold"><a href="#intro" className="vc-click">Product upload guide</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-1" className="vc-click">Step 1: Sign in</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-2" className="vc-click">Step 2: Product & Inventory</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-3" className="vc-click">Step 3: Add product button</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-4" className="vc-click">Step 4: Product title</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-5" className="vc-click">Step 5: Description</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-6" className="vc-click">Step 6: Add product images.</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-7" className="vc-click">Step 7: General information.</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-8" className="vc-click">Step 8: Metadata.</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-9" className="vc-click">Step 9: Variations.</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-10" className="vc-click">Step 10: Publish, availability & tags.</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-11" className="vc-click">Step 11: Care information.</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-12" className="vc-click">Last Step: Review and Save.</a></li>
                    </ul>
                </div>
                <div className="col-lg-7">
                    <div id="intro" className="vn-guides pt-5">
                        <h1 className="">Upload products to your store.</h1>

                        <p className="vn-guides-desc mt-3">
                            Creating and adding your products on Taojaa is incredibly easy! To make it even simpler for you, we've put together this informative guide to assist you effortlessly throughout the process.
                        </p>
                    </div>

                    <div id="step-1" className="vn-guides">
                        <p className="mt-2 mb-2 vn-guides-desc">
                            Step 1: Sign in to your account and access your dashboard. Locate the "Products and Inventory" tab on your dashboard, situated between "Invoices & Receipts" and "Discounts & Coupons.
                        </p>

                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={ProductInventoryMenu} alt="" />
                        </p>
                    </div>

                    <div id="step-2" className="vn-guides">
                        <p className="vn-guides-desc mb-2">
                            Step 2: Once you click on "Products and Inventory," you'll be presented with two options: "Products" and "Collections." Click on "Products" to proceed.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={ProductDropdownMenu} alt="" />
                        </p>
                    </div>

                    <div id="step-2" className="vn-guides">
                        <p className="vn-guides-desc mb-2">
                            Step 3: Click on "Add Product." on the product page at the top right corner of the product list card.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={AddProductButton} alt="" style={{ width: "100%" }} />
                        </p>
                    </div>

                    <div id="step-4" className="vn-guides">
                        <p className="vn-guides-desc mb-2">
                            Step 4: Start by adding a product title.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={ProductTitle} alt="" style={{ width: "100%" }} />
                        </p>
                    </div>

                    <div id="step-5" className="vn-guides">
                        <p className="mb-2 vn-guides-desc">
                            Step 5: Add a product description.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={ProductDescription} alt="" style={{ width: "100%" }} />
                        </p>
                    </div>

                    <div id="step-6" className="vn-guides">
                        <p className="mb-2 vn-guides-desc">
                            Step 6: Select images from your phone or computer.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={ProductImages} alt="" style={{ width: "100%" }} />
                        </p>
                    </div>
                    <div id="step-7" className="vn-guides">
                        <p className="mb-2 vn-guides-desc">
                            Step 7: Add general information about your product.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={GeneralDetails} alt="" style={{ width: "100%" }} />
                        </p>
                    </div>
                    <div id="step-8" className="vn-guides">
                        <p className="mb-2 vn-guides-desc">
                            Step 8: Add metadata. Metadata will help your products rank on search engines, so fill it rightly and make sure to use relevant keywords.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={MetaData} alt="" style={{ width: "100%" }} />
                        </p>
                    </div>
                    <div id="step-9" className="vn-guides">
                        <p className="mb-2 vn-guides-desc">
                            Step 9: Add variations. If there are variations like colors, mterials, packs, and sizes to your product, click on "Add Variation" and fill accordingly.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={AddVariationButton} alt="" style={{ width: "100%" }} />
                        </p>
                    </div>
                    <div id="step-10" className="vn-guides">
                        <p className="mb-2 vn-guides-desc">
                            Step 10: Before saving, scroll up. At the right corner of the page is the publish settings. Availability, Visibility, and Publish schedule are set by default, so skip to "Product Tag" and tag your product for easy classification.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={ProductSettings} alt="" style={{ width: window.innerWidth <= 480 ? '100%' : 'auto' }} />
                        </p>
                    </div>
                    <div id="step-11" className="vn-guides">
                        <p className="mb-2 vn-guides-desc">
                            Step 11: Product care information: Fill in the steps to inform your customers on how to care for the product.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={CareInformation} alt="" style={{ width: window.innerWidth <= 480 ? '100%' : 'auto' }} />
                        </p>
                    </div>
                    <div id="step-12" className="vn-guides">
                        <p className="mb-2 vn-guides-desc">
                            Step 12: Lastly, review all the product details for correctness, then save. Now your product is visible to customers. Happy selling!
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={SaveButton} alt="" />
                        </p>
                    </div>
                    <Help/>
                </div>
            </div>
        </div>
    )
}

export default HowToAddProducts