import React from 'react'
import { Link } from 'react-router-dom';

import LogoImage from '../../logo.png';

const Logo = () => {
    return (
        <div className='logo-box mt-4 px-4'>
            <Link to={'/'}><img alt='Taojaa Logo' src={LogoImage} width={100} /></Link>
        </div>
    )
}

export default Logo