import { Col, Container, Form, Tab, Tabs } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { FAQsList } from "../../data/index.jsx";
import FaqTab from "./FaqTab.jsx";
import { useMemo, useState } from "react";

const Faqs = () => {
  const [searchTerms, setSearchTerms] = useState("");

  // Use useMemo to store the filtered FAQs and update them only when the searchTerms change
  const filteredFaqs = useMemo(() => {
    // Convert the search terms to lowercase for case-insensitive searching
    const lowerCaseSearchTerms = searchTerms.toLowerCase();

    // Filter the FAQs based on whether the search terms are found in the questions or answers
    return FAQsList.map((category) => ({
      ...category,
      faqs: category.faqs.filter((faq) => {
        const lowerCaseQuestion =
          typeof faq.q === "string" ? faq.q.toLowerCase() : faq.qText ? faq.qText.toLowerCase() : "";
        const lowerCaseAnswer =
          typeof faq.a === "string" ? faq.a.toLowerCase() : faq.aText?faq.aText.toLowerCase() : "";
        return (
          lowerCaseQuestion.includes(lowerCaseSearchTerms) ||
          lowerCaseAnswer.includes(lowerCaseSearchTerms)
        );
      }),
    }));
  }, [searchTerms]);

  return (
    <>
      <Container className="mt-5 pt-5">
        <Col xl={6} lg={8} xs={12} className="mx-auto text-center">
          <h1 className="mb-5 fs-1 fw-bolder">Frequently asked questions</h1>
          <p className="text-primary">Have questions? We are here to help.</p>
          <Col sm={8} className="mx-auto my-5">
            <Form
              className="d-flex position-relative"
              onSubmit={(e) => e.preventDefault()}
            >
              <Form.Control
                type="search"
                placeholder="Search"
                aria-label="Search"
                className="ps-5"
                size="lg"
                onChange={(e) => setSearchTerms(e.target.value)}
              />
              <div className="search-icon">
                <Search />
              </div>
            </Form>
          </Col>
        </Col>
        <Col lg={10} className="mx-auto pt-5 mb-5 pb-5">
          <Tabs
            defaultActiveKey={FAQsList[0].id}
            id="faqsTabs"
            className="mb-3"
            fill
            variant="pills"
          >
            {filteredFaqs.map((faqGroup) => (
              <Tab
                title={faqGroup.name}
                eventKey={faqGroup.id}
                key={faqGroup.id}
              >
                {faqGroup.faqs.length ? (
                  <FaqTab faqs={faqGroup.faqs} />
                ) : (
                  <div className="py-4">
                    <p>No matching FAQs for the search term <span className="text-primary fw-bold">"{searchTerms}"</span> found.</p>
                  </div>
                )}
              </Tab>
            ))}
          </Tabs>
        </Col>
      </Container>
    </>
  );
};

export default Faqs;
