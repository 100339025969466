import { Image } from "react-bootstrap";
import {
    AddCouponBtn,
  CreateCouponModal,
  DiscountCoupons,
} from "../../../../assets";
import Help from "../Help";
const HowToCreateDiscountsAndCoupons = () => {
  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-lg-3 desktop relative">
          <ul className="vn-guides-sidebar border-right" id="vn-guides-sidebar">
            <li className="text-muted vn-item active mb-3">
              <a href="#intro" className="vc-click fw-bold">
                Create Discount & Coupons
              </a>
            </li>
            <li className="text-muted vn-item mb-3">
              <a href="#step-1" className="vc-click">
                Step 1: Discounts & Coupons
              </a>
            </li>
            <li className="text-muted vn-item mb-3">
              <a href="#step-2" className="vc-click">
                Step 2: Add Coupons
              </a>
            </li>
            <li className="text-muted vn-item mb-3">
              <a href="#step-3" className="vc-click">
                Step 3: Set Coupon and Save
              </a>
            </li>
          </ul>
        </div>
        <div className="col-lg-7">
          <div id="intro" className="vn-guides pt-5">
            <h1 className="">Create discounts & coupons</h1>
          </div>

          <div id="step-1" className="vn-guides">
            <p className="mt-2 mb-2 vn-guides-desc">
              Step 1: From the menu dropdown, find and click on the "Discounts &
              Coupons" option. This will take you to the section where you can
              create your discount and coupons.
            </p>

            <p className="mt-4 mb-2 vn-guides-desc">
              <Image src={DiscountCoupons} alt="" fluid/>
            </p>
          </div>

          <div id="step-2" className="vn-guides">
            <p className="mt-2 mb-2 vn-guides-desc">
              Step 2: Click on add coupon
            </p>

            <p className="mt-4 mb-2 vn-guides-desc">
              <Image src={AddCouponBtn} alt="" fluid/>
            </p>
          </div>

          <div id="step-3" className="vn-guides">
            <p className="mt-2 mb-2 vn-guides-desc">
              Step 3: Enter your desired coupon code, select the discount type
              and value, set the expiration date, specify the minimum eligible
              price, indicate the coupon status, and then click the "Save
              Coupon" button.
            </p>

            <p className="mt-4 mb-2 vn-guides-desc">
              <Image src={CreateCouponModal} alt="" fluid/>
            </p>
          </div>
          <Help />
        </div>
      </div>
    </div>
  );
};

export default HowToCreateDiscountsAndCoupons;
