import React from 'react'
import UniqueCard from '../../components/unique/Card'
import { Col, Container, Row } from 'react-bootstrap'
import imag2 from '../../components/assets/pexels-mart-page3-2.png'
import img3 from '../../components/assets/pexels-mart-page3-3.png'
import img4 from '../../components/assets/Frame-page3-4.png'
import CardImage from '../../components/unique/CardImage'
import img8 from '../../components/assets/image27-page3-1.png'
import image2 from '../../components/assets/executive-managers-page3-hero.png'
import img2b from '../../components/assets/executive-managers-page3-hero-mobile.png'
import image3 from '../../components/assets/image26.svg'
import CardText2 from '../../components/unique/CardText2'
import HeroCard2 from '../../components/unique/HeroCard2'

export default function UniquePage3() {
  return (
    <>
      <HeroCard2
        title={`Simplify your business management with Taojaa.`}
        desp={`The easiest way to run your business. Centralize inventory, orders, pricing, and more in one dashboard. Automate tasks and seamlessly integrate tools to streamline your operations. `}
        mainImg2={img2b}
        mainImg={image2}
        floatingImg2={image3}
      />

      <Container className='g2 mx-auto'>
        <Row className=' align-items-center '>
          <section
            className='box-section align-items-center'
          >
            <Row className='align-items-center '>
              <Col data-aos='fade-up' data-aos-duration='1000' md={6} sm={12}>
                <CardImage imgSrc={img8} />
              </Col>
              <Col className='order-md-first' data-aos='fade-up' data-aos-duration='1000' md={6} sm={12}>
                <CardText2
                  btnUrl={`Get Started`}
                  desc={`  Taojaa offers a robust order management system. Track orders, manage shipping, and handle returns efficiently. This feature ensures a smooth workflow from the placement of an order to its fulfillment.`}
                  title={`Efficient Order Management`}
                />
              </Col>
            </Row>
          </section>
          <section
            className=' box-section align-items-center'
          >
            <Row>
              <Col md={6} sm={12} className='mb-3' data-aos='fade-up' data-aos-duration='1000'>
                <UniqueCard
                  btnUrl={`Get Started`}
                  desc={`Gain control over your inventory with Taojaa's comprehensive features. Monitor stock levels, receive low stock alerts, and track product movements effortlessly.`}
                  title={`Comprehensive Inventory Control`}
                  imgSrc={imag2}
                />
              </Col>
              <Col md={6} sm={12} className='mb-3' data-aos='fade-up' data-aos-duration='1000'>
                <UniqueCard
                  btnUrl={`Get Started`}
                  desc={` Benefit from excellent customer support and engagement features. From resolving queries to engaging with customers, Taojaa ensures you are never alone in managing your business.`}
                  title={`Customer Support and Engagement`}
                  imgSrc={img3}
                />
              </Col>
            </Row>
          </section>
          <section
            className='box-section align-items-center'
          >
            <Row>
              <Col md={6} sm={12} className='mb-3' data-aos='fade-up' data-aos-duration='1000'>
                <UniqueCard
                  btnUrl={`Get Started`}
                  desc={`Simplify collaboration and task assignments within your team.`}
                  title={`Staff Management`}
                  imgSrc={img4}
                />
              </Col>
              <Col md={6} sm={12} className='mb-3' data-aos='fade-up' data-aos-duration='1000'>
                <UniqueCard
                  btnUrl={`Get Started`}
                  desc={`Taojaa provides a single, user-friendly dashboard where you can control all aspects of your business operations. Manage inventory, orders, and pricing seamlessly from one place.`}
                  title={`Centralized Dashboard`}
                  imgSrc={imag2}
                />
              </Col>
            </Row>
          </section>
        </Row>
      </Container>
    </>
  )
}
