import React from 'react'

const Help = () => {
    return (
        <div id="help" className="vn-guides pt-4">
            <p className="mb-2 vn-guides-desc">
                Need further help? Reach out to us: <a href='mailto:support@taojaa.com'>support@taojaa.com</a>. Happy Selling on Taojaa.
            </p>
        </div>
    )
}

export default Help