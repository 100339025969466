import { CreateOrderModal, OrderCreateBtn, OrderMenu } from "../../../../assets";
import Help from "../Help";

const HowToCreateOrders = ()=>{
    return (
        <div className='container mb-5'>
            <div className='row'>
                <div className='col-lg-3 desktop relative'>
                    <ul className="vn-guides-sidebar border-right" id="vn-guides-sidebar">
                        <li className="text-muted vn-item active mb-3 fw-bold"><a href="#intro" className="vc-click">Create orders/Record sales</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-1" className="vc-click">Step 1: Go to Orders</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-2" className="vc-click">Step 2: Create order button</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-3" className="vc-click">Step 3: Add Order Details & Save.</a></li>
                    </ul>
                </div>
                <div className="col-lg-7">
                    <div id="intro" className="vn-guides pt-5">
                        <h1 className="">Create order/Record sales on Taojaa.</h1>
                    </div>

                    <div id="step-1" className="vn-guides">
                        <p className="mt-2 mb-2 vn-guides-desc">
                            Step 1: From the menu dropdown, find and click on the "Orders" option. This will take you to the section where you can create your orders.
                        </p>

                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={OrderMenu} alt="" />
                        </p>
                    </div>

                    <div id="step-2" className="vn-guides">
                        <p className="mt-2 mb-2 vn-guides-desc">
                            Step 2: Navigate to the upper right corner of the website and select the "Create Order" option.
                        </p>

                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={OrderCreateBtn} alt="" />
                        </p>
                    </div>

                    <div id="step-3" className="vn-guides">
                        <p className="mt-2 mb-2 vn-guides-desc">
                            Step 3: Provide the customer's name, selected products, order date, chosen payment method, shipping fee, payment status, order status, sales channel, and include any relevant coupons if applicable. And then proceed to click the “Save and continue” button
                        </p>

                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={CreateOrderModal} alt="" />
                        </p>
                    </div>
                    <Help/>
                </div>
            </div>
        </div>
    );
}

export default HowToCreateOrders;