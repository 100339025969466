import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Iphone12MockUp } from '../../assets'

const Offerings = ({ data }) => {
  return (
    <section>
      <Container>
        <Row className='pt-5 mb-5 mt-5'>
          <Col xs={7} sm={7} lg={7} className='pt-md-5' data-aos="fade-up" data-aos-duration="1000">
            <Col lg={12} className='position-relative align-items-center mt-md-5'>
              <span className='position-absolute' style={{ top: -40, left: -10 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="71" height="101" viewBox="0 0 71 101" fill="none">
                  <path d="M69 25.5078C51.4257 8.73257 20.4051 -14.388 29.0855 19.1503C29.8379 22.0574 27.4334 24.955 24.4422 24.6909C5.70669 23.0372 -12.6773 27.6666 24.6685 56.2998C27.2559 58.2836 26.9451 62.7547 24.3875 64.7767C13.5772 73.3234 -2.61249 93.2955 41.1228 99" stroke="#8A50FC" strokeWidth="4" />
                </svg>
              </span>
              <h2 className='ms-4 offering-title'>Launch and grow your</h2>
            </Col>
          </Col>
          <Col xs={5} sm={5} lg={5} className='mb-5' data-aos="fade-left" data-aos-duration="1000">
            <div className='sub-cat shadow-lg position-relative' style={{ backgroundImage: `url("${data.main.img}")`, backgroundSize: 'cover' }}>
              <div className='sub-cat-overlay position-absolute'>
                <h6 className='text-white sub-cat-name'>{data.main.name}</h6>
              </div>
            </div>
          </Col>
          {data.subCategories.map((category, index) =>
            <Col xs={6} sm={6} lg={6} className='mb-5' data-aos={`${((index % 2) > 0) ? 'fade-right' : 'fade-left'}`} data-aos-duration="1000" style={{ marginTop: ((index % 2) > 0) ? 0 : -40 }} key={index}>
              <div className='sub-cat others shadow-lg position-relative' style={{ backgroundImage: `url("${category.img}")`, backgroundSize: 'cover' }}>
                <div className='sub-cat-overlay position-absolute'>
                  <h6 className='text-white sub-cat-name'>{category.name}</h6>
                </div>
              </div>
            </Col>
          )}
          <Row className='justify-content-center mt-3 mb-5' data-aos="fade-up" data-aos-duration="1000">
            <Col lg={8} className='position-relative'>
              <h2 className='ms-4 offering-title'>Business successfully online.</h2>
              <span className='position-absolute' style={{ bottom: -50, right: -30 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="71" height="101" viewBox="0 0 71 101" fill="none">
                  <path d="M2 75.4922C19.5743 92.2674 50.5949 115.388 41.9145 81.8497C41.1621 78.9426 43.5666 76.045 46.5578 76.3091C65.2933 77.9628 83.6773 73.3334 46.3315 44.7002C43.7441 42.7164 44.0548 38.2453 46.6125 36.2233C57.4228 27.6766 73.6125 7.70451 29.8772 2.00001" stroke="#8A50FC" strokeWidth="4" />
                </svg>
              </span>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col lg={6}>
            <Row>
              {data.offerings.map((offering, index) =>
                <Col lg={6} className='mt-4' key={index} data-aos="fade-right" data-aos-duration="1000">
                  <h3>{offering.title}</h3>
                  <p>{offering.content}</p>
                </Col>
              )}
            </Row>
          </Col>
          <Col lg={6} data-aos="fade-left" data-aos-duration="1000">
            <Image src={Iphone12MockUp} width={"100%"} />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Offerings