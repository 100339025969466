import React from 'react'
const Modal = (props) =>
{
    if (!props.show)
    {
        return null
    }

    return (
        <div className="tj-modal" onClick={props.onClose}>
            <div className="tj-modal-content" onClick={e => e.stopPropagation()}>
                <div className="tj-modal-body d-flex flex-column align-items-center justify-content-center">
                    {props.icon}
                    <div>{props.body} </div>

                </div>
            </div>
        </div>
    )
}

export default Modal