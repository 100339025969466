import React, { useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { ReactComponent as Signin1svg } from '../assets/Signin1svg.svg';
import ValidateSubmit, { getCookie, randomString } from 'wearslot-dev-utils';
import { messageHandler } from '../../bundles/app'
import Slider from '../Slider';
import { useState } from 'react';
import Logo from '../Shared/Logo';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from 'react-icons/fc';
import { getContinueLink } from './Success';
import { AES } from 'crypto-js';


const CreateAccount = () => {

    document.title = 'Create Account - Taojaa';

    const [loading, setLoading] = useState(false);
    const [registered, setRegistered] = useState(false);
    const [visibility, setVisibility] = useState(false)
    const [user, setUser] = useState({
        name: "",
        email: "",
        phone: "",
        password: "",
        confirm_password: '',
        country_id: 120,
        sign_up_method: 1,
        account_type: 'is_vendor',
    });

    useEffect(() => {
        if (getCookie(process.env.REACT_APP_COOKIE_NAME)) {
            return window.location = getContinueLink();
        }

        if (localStorage.getItem("email")) {
            setUser({ ...user, email: localStorage.getItem('email') })
        }
    }, [user, setUser]);

    const googleLogin = useGoogleLogin({
        onSuccess: codeResponse => {
            getUserProfile(codeResponse);
        },
    });

    async function getUserProfile(codeResponse) {

        try {

            const response = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
                headers: {
                    Authorization: `Bearer ${codeResponse.access_token}`,
                    Accept: 'application/json'
                }
            })

            if (response.data) {
                var userData = { ...user };
                userData.email = response.data.email;
                userData.name = response.data.name;
                userData.password = '';
                userData.confirm_password = '';
                userData.sign_up_method = 2;

                createUserAccount(userData);
            }

        } catch (error) {
            console.log(error)
            return messageHandler("error", "Unable to get auth user profile.");
        }
    }

    function createUserAccount(data) {

        setLoading(true);

        if(getCookie('ref_id')) {
            data.ref_id = getCookie('ref_id')
        }

        if(getCookie('affiliate')) {
            data.affiliate = getCookie('affiliate')
        }

        axios.post('/account/create-account', data).then(response => {
            if (response.data.success) {
                localStorage.setItem("details", JSON.stringify(data))
                localStorage.setItem("user", JSON.stringify(response.data.user))

                messageHandler('success', response.data.message);

                const randomString1 = randomString(56)
                const randomString2 = randomString(56)

                const token =  AES.encrypt(`${randomString1}:${response.data.token}:${randomString2}`, process.env.REACT_APP_CRYPTO_SECRET_KEY).toString();
                localStorage.setItem("token", token);

                return setRegistered(true)
            }

        }).catch(error => {
            setLoading(false)
            if (error.response) {
                return messageHandler("error", error.response.data.message);
            }
            return messageHandler("error", "Something went wrong.");
        })
    }

    const handleInput = (e) => {

        var field = e.target.name;
        var value = e.target.value;

        setUser({ ...user, [field]: value });
    }

    const handleSubmit = e => {
        e.preventDefault()

        if (ValidateSubmit(false, true)) {

            if (user.password !== user.confirm_password) {
                messageHandler('error', 'Passwords do not match');
                return;
            }

            var userData = { ...user };
            createUserAccount(userData);
        }
    }


    if (registered) {
        return <Navigate to={'/auth/store-details'} />
    }

    return (
        <div className='col-md-12'>
            <div className='tj-form-response'>
                <p id='tj-resp-Message'></p>
            </div>
            <div className='row tj-everything'>
                <div className='col-md-6'>
                    <Logo />
                    <div className='d-flex justify-content-center mt-3'>
                        <div className='tj-box'>
                            <div className='text-center-mobile'>
                                <h1 className='mb-1'>Create an account</h1>
                                <h2>Fill in your details to get started.</h2>
                            </div>
                            <div>
                                <button className='tj-google d-flex align-items-center justify-content-center' onClick={() => googleLogin()}>
                                    <FcGoogle />
                                    <span>Sign up with Google</span>
                                </button>
                                <div className='tj-line d-flex align-items-center mt-3 gap-1'>
                                    <span></span>
                                    <div>or</div>
                                    <span></span>
                                </div>
                            </div>
                            <form>
                                <div className="tj-form mb-2 position-relative">
                                    <div className='form-group mb-2'>
                                        <label htmlFor="name" className="form-label">Your Full Name</label>
                                        <input
                                            type={"text"}
                                            name={"name"}
                                            value={user.name}
                                            onChange={e => handleInput(e)}
                                            validatefield='true'
                                            validationoutput='#fullnameerror'
                                            validationmessage='Full Name cannot be empty!!'
                                            className="form-control"
                                            id="name"
                                            placeholder='John Doe'
                                        />
                                        <span id='fullnameerror'></span>
                                    </div>

                                    <div className='form-group mb-2'>
                                        <label htmlFor="Email" className="form-label">Your Email</label>
                                        <input
                                            type={"email"}
                                            name={"email"}
                                            value={user.email}
                                            onChange={e => handleInput(e)}
                                            validatefield='true'
                                            validationoutput='#emailerror'
                                            validationmessage='Email cannot be empty!!'
                                            className="form-control"
                                            id="Email"
                                            aria-describedby="emailHelp"
                                            placeholder='example@gmail.com' />
                                        <span id='emailerror'></span>
                                    </div>

                                    <div className='form-group mb-2'>
                                        <label htmlFor="Email" className="form-label">Phone Number  (Join our community)</label>
                                        <input
                                            type={"text"}
                                            name={"phone"}
                                            value={user.phone}
                                            onChange={e => handleInput(e)}
                                            validatefield='true'
                                            validationoutput='#phoneerror'
                                            validationmessage='Phone number cannot be empty!!'
                                            className="form-control"
                                            id="Phone"
                                            aria-describedby="phoneHelp"
                                            placeholder='+234 080xxxxxxx' />
                                        <span id='phoneerror'></span>
                                    </div>

                                    <div className='form-group mb-2' style={{ position: 'relative' }}>
                                        <label htmlFor="Password" className="form-label">Password</label>
                                        <input
                                            type={visibility ? "text" : "password"}
                                            name={"password"}
                                            value={user.password}
                                            onChange={e => handleInput(e)}
                                            validatefield="true"
                                            validationoutput="#passwordError"
                                            validationmessage="Password cannot be empty!*"
                                            className="form-control"
                                            id="Password"
                                            placeholder='Password' />
                                        {visibility
                                            ? <AiOutlineEye className='tj-icon1' style={{ top: '63%' }} onClick={() => setVisibility(false)} />
                                            : <AiOutlineEyeInvisible className='tj-icon1' onClick={() => setVisibility(true)} style={{ top: '63%' }} />
                                        }
                                    </div>

                                    <div className='form-group mb-2' style={{ position: 'relative' }}>
                                        <label htmlFor="CofirmPassword" className="form-label">Confirm Password</label>
                                        <input
                                            type={visibility ? "text" : "password"}
                                            name={"confirm_password"}
                                            value={user.confirm_password}
                                            onChange={e => handleInput(e)}
                                            validatefield="true"
                                            validationoutput="#passwordError"
                                            validationmessage="Password cannot be empty!*"
                                            className="form-control"
                                            id="CofirmPassword"
                                            placeholder='Confirm password' />
                                        {visibility
                                            ? <AiOutlineEye className='tj-icon1' style={{ top: '63%' }} onClick={() => setVisibility(false)} />
                                            : <AiOutlineEyeInvisible className='tj-icon1' style={{ top: '63%' }} onClick={() => setVisibility(true)} />
                                        }
                                    </div>
                                </div>
                                <button className={`tj-box1 mt-4 mb-3 ${loading ? 'button-disabled' : ''}`} disabled={loading} onClick={handleSubmit}>
                                    {loading ? <lord-icon
                                        src="https://cdn.lordicon.com/ymrqtsej.json"
                                        trigger="loop"
                                        colors="primary:#ffffff"
                                        style={{ width: "50px", height: "50px" }}>
                                    </lord-icon> : 'Proceed'}
                                </button>
                            </form>
                            <div className='d-flex justify-content-center gap-1 mt-2 tj-acct'>
                                <span>Already have an account?</span>
                                <Link to={'/auth/login'} style={{ color: '#8A50FC', textDecoration: 'none' }}>Log in</Link>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center mt-2'>
                        <div style={{ marginTop: 4, width: '78%' }}>
                            <div className='consent-box text-center'>
                                <p style={{ fontSize: 12 }}>
                                    By clicking on the button above, you have agreed to the <Link to={'/terms'} style={{ color: '#8A50FC' }} className='text-underline'>Terms of Service</Link> and <Link to={'/privacy-policy'} style={{ color: '#8A50FC' }} className='text-underline'>Privacy Policy</Link>  We'll send you email related to your account and updates about our services, you can unsubscribe at anytime.
                                </p>
                            </div>
                            <Slider selected={1} marginTop={2} />
                        </div>
                    </div>
                </div>
                <div className='col-md-6 desktop'>
                    <Signin1svg className='tj-right' />
                </div>
            </div >
        </div>
    )
}

export default CreateAccount;