import HeroImg from "./HeroImg";
import TeamsImg from "./teams.jpg";
import OfferImg from "./offer.jpg";
import MissionImg from "./mission.jpg";
import VisionImg from "./vision.jpg";
import AboutHeroImg from "./about-hero.png";
import NeeBlizImg from './nee-bliz.jpg';
import CJLeapHubImg from './cj-leap-hub.jpg';
import BeadByFiaImg from './bead-by-fia.jpg';
import AnalyticsImg from './analytics-img.png'
import InventoryImg from "./inventory-management-img.png";
import PaymentsImg from "./transactions-payments-img.png";
import OrderSalesImg from "./orders-sales-management-img.png";
import InvoicingImg from "./invoicing-reciepts-img.png";
import IntegratedCRMImg from "./integrated-crm-img.png";
import DiscountsCouponsImg from "./discounts-coupons-img.png";
import OrderMenu from "./orders-menu.png";
import OrderCreateBtn from "./create-order-btn.png";
import CreateOrderModal from "./create-order-modal.png";
import InvoiceDetails from "./invoice-details.png";
import DownloadInvoiceBtn from "./download-invoice-btn.png";
import ShareInvoiceBtn from "./share-invoice-btn.png";
import WithdrawBalanceBtn from "./withdraw-balance-link.png";
import WithdrawFundsBtn from "./withdraw-funds-link.png";
import WithdrawalModal from "./withdrawal-modal.png";
import DiscountCoupons from "./discount-coupons.png";
import AddCouponBtn from "./add-coupon.png";
import CreateCouponModal from "./create-coupon-modal.png";
import FashionFrame from './Frame 1000002641.png';
import StorefrontImg from './webpage1.png'
import FinanceImg from './finance1.png';
import InsightImg from './insight1.png';
import SaleImg from './sale1.png';
import CustomerServiceImg from './customer-service1.png';
import WristWatches from './Frame 1000003001.png';
import MensShoes from './menshoesc6.png';
import WomensShoes from './WomensShoes.png';
import WomenWears from './womensWears.png';
import MensWears from './menswears.png';
import Bags from './bags.png';
import Jewelry from './Jewelry.png';
import Iphone12MockUp from './iPhone 13.png';
import CustomerLoyaltyImg from './CustomerLoyalty.png';
import EngageWithCustomerImg from './EngageWithCustomers.png';
import PromotionsImg from './Promotions.png';
import InventoryManagementImg from './InventoryManagement.png';
import RealMeMockUp from './Realme 10.png';
import RealTimeTracking from './data 1.png';
import MarketingPerformance from './kpi 1.png';
import CustomerInsight from './insight 1.png';
import InventoryAlert from './inventory 1.png';
import RealMe11Mockup from './Realme 11.png'
import FashionDress from './dress.png';
import DashboardView from './DashboardView.png'
import PaymentSetupMenu from './PaymentSetupMenu.png'
import CurrencySettings from './CurrencySettings.png'
import AddCurrencyButton from './AddCurrencyButton.png'
import BankAccountSettings from './BankAccountSettings.png'
import SelectAndSaveCurrency from './SelectAndSaveCurrency.png'
import AddBankButton from './AddBankButton.png'
import BankInfoModal from './BankInfoModal.png'
import LogoImg from "../logo.png"

//new hero images
export const HeroOne = require('./heroOne.png')
export const ImgSide = require('./imgSide.png')
export const SalesImg1 = require('./salesImg1.png')
export const SalesImg2 = require('./salesImg2.png')
export const SalesImg3 = require('./salesImg3.png')
export const GrowImg1 = require('./growbiz1.png')
export const GrowImg2 = require('./growbiz2.png')
export const GrowImg3 = require('./growbiz3.png')
export const SellImg = require('./sellImg.png')
export const MultiImg = require('./multiImg.png')
export const GoogleImg = require('./google.png')
export const PinterestImg = require('./pinterest.png')
export const InstagramImg = require('./instagram.png')
export const FacebookImg = require('./facebook.png')
export const Bar = require('./bar.png')
export const OptsImg = require('./OptsImg.png')

const Logo = () => {
  return <img alt="Taojaa Logo" src={LogoImg} style={{ width: 100 }} />;
};

export {
  Logo,
  HeroImg,
  TeamsImg,
  OfferImg,
  MissionImg,
  VisionImg,
  AboutHeroImg,
  NeeBlizImg,
  CJLeapHubImg,
  BeadByFiaImg,
  AnalyticsImg,
  InventoryImg,
  PaymentsImg,
  IntegratedCRMImg,
  InvoicingImg,
  OrderSalesImg,
  DiscountsCouponsImg,
  OrderMenu,
  OrderCreateBtn,
  CreateOrderModal,
  InvoiceDetails,
  DownloadInvoiceBtn,
  ShareInvoiceBtn,
  WithdrawBalanceBtn,
  WithdrawFundsBtn,
  WithdrawalModal,
  DiscountCoupons,
  AddCouponBtn,
  CreateCouponModal,
  FashionFrame,
  StorefrontImg,
  FinanceImg,
  SaleImg,
  CustomerServiceImg,
  InsightImg,
  WristWatches,
  MensShoes,
  MensWears,
  WomenWears,
  WomensShoes,
  Bags,
  Jewelry,
  Iphone12MockUp,
  CustomerLoyaltyImg,
  EngageWithCustomerImg,
  PromotionsImg,
  InventoryManagementImg,
  RealMeMockUp,
  RealMe11Mockup,
  RealTimeTracking,
  MarketingPerformance,
  CustomerInsight,
  InventoryAlert,
  FashionDress,
  DashboardView,
  PaymentSetupMenu,
  CurrencySettings,
  AddCurrencyButton,
  BankAccountSettings,
  SelectAndSaveCurrency,
  AddBankButton,
  BankInfoModal
};
