import React from 'react'
import Hero from './Hero'
import { useParams } from 'react-router-dom'
import { Categories, FAQsList } from '../../data';
import Offerings from './Offerings';
import Benefits from './Benefits';
import Banner from './Banner';
import AnalyticsAndInsights from './AnalyticsAndInsights';
import Banner2 from './Banner2';
import FaqTab from '../Learn/FaqTab';
import { Col, Container, NavLink } from 'react-bootstrap';

const Category = () => {

    const { name } = useParams();
    const data = Categories[name];

    return (
        <>
            <Hero data={data} />
            <Offerings data={data} />
            <Benefits data={data} />
            <Banner data={data} />
            <AnalyticsAndInsights data={data} />
            <Banner2 data={data} />

            <Container className='my-5 pt-5'>
                <Col className='text-center mb-4'>
                    <h3 className='section-title'>Frequently Asked questions</h3>
                </Col>
                <FaqTab faqs={FAQsList[0].faqs.filter((item, index) => index > 2 && index < 8)}/>
                <Col className='text-center mt-4'>
                    <NavLink href='/resources/faqs' className='text-primary fw-bold'>See all Faqs</NavLink>
                </Col>
            </Container>
        </>
    )
}

export default Category