import React from 'react'
import Help from '../Help'

const HowToCompleteStoreSetup = () => {

    return (
        <div className='container mb-5'>
            <div className='row'>
                <div className='col-lg-3 desktop relative'>
                    <ul className="vn-guides-sidebar border-right" id="vn-guides-sidebar">
                        <li className="text-muted vn-item active mb-3 fw-bold"><a href="#intro" className="vc-click">Complete store setup</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#video-guide" className="vc-click">Watch Video Guide</a></li>
                    </ul>
                </div>
                <div className="col-lg-7">
                    <div id="intro" className="vn-guides pt-5">
                        <h1 className="">Complete store setup and customize storefront.</h1>

                        <p className="vn-guides-desc mt-3">
                            Complete your store setup and customize your storefront to your desired look on Taojaa.
                        </p>
                    </div>

                    <div id="video-guide" className="vn-guides">
                        <p className="mt-2 mb-2 vn-guides-desc">
                            <iframe width="100%" height={window.innerWidth <= 480 ? "207" : "453"} src="https://www.youtube.com/embed/vTKPFyHBnig" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </p>
                    </div>
                    <Help/>
                </div>
            </div>
        </div>
    )
}

export default HowToCompleteStoreSetup