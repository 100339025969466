import $ from 'jquery';

export function ClearAppMessage(output) {
    $(output).html("");
}

export function AppMessage(status, message, output) {
    var style = '';
    if (status === "success") {
        style = "alert-success";
    } else if (status === "error") {
        style = "alert-danger";
    } else if (status === "info") {
        style = "alert-info";
    } else if (status === "warning") {
        style = "alert-warning";
    }

    var content =
        `<div class="appMessage alert d-flex justify-content-between ` +
        style +
        ` fade show mb-2" role="alert">
            <div class="alert-text">` +
        message +
        `</div>
            <div class="alert-close">
                <span 
                  class="close appMessageClose" 
                  data-dismiss="alert" 
                  style="cursor:pointer;" 
                  aria-label="Close">✕</span>
            </div>
        </div>`;

    $(output).html(content);

    $(".appMessageClose").on("click", function () {
        $(".appMessage").remove();
    });

    setTimeout(function () {
        $(".appMessage").fadeOut();
    }, 6000);
}

export function isLoggedIn() {

    var token = localStorage.getItem("token");
    var company = localStorage.getItem("company");
    var details = localStorage.getItem("details");

    if (company != null && details != null && token != null) {
        return true;
    }

    return false;
}

export var Selected = function (value, option) {
    if (value === option) {
        return "selected";
    } else {
        return "";
    }
};


export const messageHandler = (state, message) => {

    $(".tj-form-response").fadeIn();
    if (state === 'error') {
        $("#tj-resp-Message").attr('class', 'error').html(message);
    } else if (state === 'success') {
        $("#tj-resp-Message").attr('class', 'success').html(message);
    }

    setTimeout(() => {
        $(".tj-form-response").fadeOut();
    }, 10000);
};

export function toggleModal(target = null) {

    $("body").css("overflow-y", "hidden");

    target !== null ?
        target.toggleClass("show")
        : $(".sf-modal").toggleClass("show");

    $(".sf-modal-backdrop").toggleClass("show");
}
