import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { ArrowRight } from 'react-bootstrap-icons'

export default function CardText({ btnUrl, desc, title }) {
  return (
    <Card
      style={{
        borderRadius: '15px',
        border: '0',
      }}
    >
      <Card.Body className=''>
        <h1 className='card-title-text fw-bolder'>{title}</h1>
        <Card.Text className='card-body-text'>{desc}</Card.Text>
        <Button
          className='card-button btn-primary btn'
          variant=''
          href='/auth/create-account'
        >
          {btnUrl} <ArrowRight className='fw-bold' />
        </Button>
      </Card.Body>
    </Card>
  )
}
