import { Image } from "react-bootstrap";
import { ShareInvoiceBtn } from "../../../../assets";
import Help from "../Help";
const HowToGenerateInvoiceLink = () => {
  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-lg-3 desktop relative">
          <ul className="vn-guides-sidebar border-right" id="vn-guides-sidebar">
            <li className="text-muted vn-item active mb-3 fw-bold">
              <a href="#intro" className="vc-click">
                Generate Invoice Link
              </a>
            </li>
            <li className="text-muted vn-item mb-3">
              <a href="#step-1" className="vc-click">
                Step 1: Copy Link Button
              </a>
            </li>
          </ul>
        </div>
        <div className="col-lg-7">
          <div id="intro" className="vn-guides pt-5">
            <h1 className="">Generate invoice link.</h1>
          </div>

          <div id="step-1" className="vn-guides">
            <p className="mt-2 mb-2 vn-guides-desc">
              Step 1: From the menu dropdown, find and click on the "Invoices & Receipts" option. Next, find the specific invoice you wish to share and click on the copy link button (first button on the far right)
            </p>

            <p className="mt-4 mb-2 vn-guides-desc">
              <Image src={ShareInvoiceBtn} alt="" fluid />
            </p>
          </div>
          <Help />
        </div>
      </div>
    </div>
  );
};

export default HowToGenerateInvoiceLink;
