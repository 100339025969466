import React from 'react'

const PrivacyPolicy = () => {

    document.title = 'Privacy and Policy - Taojaa | Smart business management tool, for SMEs and local businesses.'

    return (
        <div className='container mb-5'>
            <div className='row'>
                <div className='col-lg-3 desktop relative'>
                    <ul className="vn-guides-sidebar border-right" id="vn-guides-sidebar">
                        <li className="text-muted vn-item active mb-3"><a href="#intro" className="vc-click">Introduction</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#information-we-collect" className="vc-click">Information we collect</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#why-we-collect" className="vc-click">Why Taojaa collect data</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#sharing-your-information" className="vc-click">Sharing Your Information</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#secure-your-information" className="vc-click">Secure your Information</a></li>
                        <li className="text-muted vn-item mb-3">
                            <a href="#access-update-and-delete-your-information" className="vc-click">
                                Access, Update and Delete <br /> Your Information</a>
                        </li>
                        <li className="text-muted vn-item mb-3"><a href="#control-your-information" className="vc-click">Control your Information</a></li>
                    </ul>
                </div>
                <div className="col-lg-9">
                    <div id="intro" className="vn-guides pt-5">
                        <h1 className="">Introduction To Taojaa Privacy Policy</h1>

                        <p className="mb-2 vn-guides-desc">
                            <b>This Policy covers how and why we collect, use, share, and protect your personal information:</b>
                        </p>
                        <p className="vn-guides-desc mt-2">
                            Taojaa is committed to assuring your information is safe and secure on our platform.
                            Personal Information are informations that indentifies you or that can be linked to informations that
                            identifies you. For example when you place an order, or perform any activity that requires taking pesonal
                            information.
                        </p>
                    </div>

                    <div id="information-we-collect" className="vn-guides mt-5 pt-5">
                        <h1 className="">What Information does Taojaa Collect?</h1>

                        <p className="mb-2 vn-guides-desc">
                            <b>Taojaa collects the below stated information as seen in this policy:</b>
                        </p>
                        <p className="mt-2 mb-2 vn-guides-desc">We collect information to deliver the products and services you request, to help improve your shopping experience, and to support our business functions. We collect information from you in a variety of ways when you interact with our stores, websites, and mobile services.</p>

                        <p className="vn-guides-desc mt-4"><b>What we collect and how we collect:</b></p>
                        <ul className="vn-guides-ul">
                            <li>When Signing up on Taojaa you will be required to provide your <b>Email Address, Names, and Password</b> to get started</li>
                            <li>After a complete registration, you'll get a prompt to complete you profile for better user/shopping experience this will require you to provide your <b> Business Information, Date of Birth, Gender and Phone number.</b></li>
                            <li>When you are about to place an order, You'll be required to provide your <b>Shipping Address,</b> this will be used when shipping your orders.</li>
                        </ul>
                        <p className="vn-guides-desc mt-2">All information recieved by Taojaa are the information provided by you from your devices during your activities on our platform, Taojaa as well keep records of your search and browsing histories for better and advance user/shopping experience. </p>
                    </div>

                    <div id="why-we-collect" className="vn-guides mt-5 pt-5">
                        <h1 className="">Why do we collect this information?</h1>
                        <p className="vn-guides-desc mb-2">
                            <b>We use data to provide, maintain improve and  develop our services.</b>
                        </p>
                        <p className="mb-2 vn-guides-desc">
                            Our goal is to provide you with better user/shopping experience in order to fulfill your requests for products and service personalization.

                            <br /><br /> We use informations we collect to customize our services for your, providing you with recommendations, personalised contents and relevant search results.

                            <br /><br /> Taojaa also use your personal information to support our business functions, such as fraud prevention, marketing and legal functions.
                        </p>
                        <p className="vn-guides-desc mb-2">
                            <b>You can see below how we use the information you provide:</b>
                            <ul className="vn-guides-ul">
                                <li>To create and maintain your account.</li>
                                <li>To fulfill your order or requests for services and provide customer service.</li>
                                <li>To conduct auditing and monitoring of transactions and engagement.</li>
                                <li>To update our operational and technical functionality.</li>
                                <li>To conduct business analysis, such as analytics, projections, identifying areas for operational improvement.</li>
                                <li>To conduct research and development.</li>
                                <li>To fulfill our legal function or obligations</li>
                            </ul>
                        </p>
                    </div>

                    <div id="sharing-your-information" className="vn-guides mt-5 pt-5">
                        <h1 className="">How Do We Share Your Personal Information within & outside of Taojaa?</h1>
                        <p className="vn-guides-desc mb-2">
                            <b>
                                We do not share your personal information with companies, organisations or
                                individuals outside of Taojaa except in the following cases:
                            </b>
                        </p>
                        <p className="mb-2 vn-guides-desc">
                            We share personal information about you with service providers that help with
                            our business activities, including shipping vendors, billing and refund vendors,
                            payment card processors, marketing and advertising vendors, and companies that
                            help us improve our products and services. We require our service providers to
                            keep your personal information secure. We do not allow our service providers to
                            use or share your personal information for any purpose other than providing
                            services on our behalf. Your personal information may be stored and processed
                            by our service providers in different locations where the service
                            providers or Taojaa maintain facilities.
                            <br />
                            To ensure that our products and services comply with our third-party Data Policy we've added a disclosure at the end of this policy page. You can find it <a href='#privacy-policy-disclosure'>here</a>
                        </p>
                        <p className="mb-2 vn-guides-desc">
                            <b>With your consent</b>
                        </p>
                        <p className="mb-2 mt-1 vn-guides-desc">
                            We may share your personal information outside Taojaa by your consent
                            during any of your activities that requires or recommend such on our services to ensure we bring you
                            valuable products and service satisfaction.
                        </p>
                        <p className="mb-2 vn-guides-desc">
                            <b>Bringin Ads For Related Activities</b>
                        </p>
                        <p className="mb-2 mt-1 vn-guides-desc">
                            We may share aggregated or pseudonymous information (pseudonymous information
                            includes things like: device identifier, type of device, IP addresses, cookies
                            and other information associated with your browsing and app usage) with other
                            companies, such as publishers, advertisers, measurement analytics providers,
                            and others. We do not share information that directly identifies you (information
                            such as name or email address) with these companies.
                        </p>
                    </div>

                    <div id="secure-your-information" className="vn-guides mt-5 pt-5">
                        <h1 className="">Securing Your Information</h1>
                        <p className="vn-guides-desc mb-2">
                            At Taojaa we belive privacy is power and essential, reason why we have prioritize user's privacy and security
                            on our platform and services. We use reasonable security measures, including physical,
                            administrative, and technical safeguards to protect your personal information.
                        </p>
                        <p className="vn-guides-desc mb-2">
                            We have a team of associates who are responsible for helping to protect the security of your information.
                            Whether you are shopping on our websites, through our mobile services, or in our online store, we use
                            reasonable security measures, including physical, administrative, and technical safeguards. These
                            measures may include physical and technical security access controls or other safeguards,
                            information security technologies and policies, procedures to help ensure the appropriate
                            disposal of information, and training programs.
                        </p>
                        <p className="vn-guides-desc mb-2">
                            <b>
                                We recommend you prevent all form of disclosure of your personal and account information
                                away from any third-party services outside Taojaa and it's service providers.
                            </b>
                        </p>
                        <p className="mb-2 vn-guides-desc">
                            Do not by any means give out your Taojaa account details and personal information to any other services
                            outside Taojaa, it is recommended to close all tabs opened on your browser after all your activities
                            on Taojaa. <br /><br />
                            Taojaa has provide you with some security features to safely secure your account details and personal
                            information with us, such as <b>Two Factor Authentication (2FA), Account Password and Verifications</b>.
                        </p>
                    </div>

                    <div id="access-update-and-delete-your-information" className="vn-guides mt-5 pt-5">
                        <h1 className="">
                            How You Can Access, Update and Delete Your Personal Information
                        </h1>

                        <p className="mb-2 vn-guides-desc">
                            We provide you with various ways to access or update your personal information,
                            including contact and account information. We also take reasonable steps to keep
                            your personal information accurate and complete.
                        </p>
                        <p className="mb-2 vn-guides-desc">
                            You can access or update your personal information, including contact or account information, in the following ways: <br />
                            <ul className="vn-guides-ul">
                                <li>
                                    If you have created an account on one of our websites or mobile services, log into your account.
                                    Once you do, you will be able to enter and update your own contact information and
                                    payment information, as well as contact information for recipients you have designated.
                                </li>
                                <li>You can as well contact us using our Contact Mediums to acknowledge or perform any form of manipulation on your personal information, due to how it has been stated in Taojaa's <a href="/terms-and-conditions" className="text-primary">Terms & Conditions</a> <i>("you can visit our the page to learn more").</i>
                                </li>
                            </ul>
                        </p>
                    </div>

                    <div id="control-your-information" className="vn-guides mt-5 pt-5">
                        <h1 className="">You Have Full Access And Control Over Your Personal Information</h1>

                        <p className="mb-2 vn-guides-desc">
                            You are not limited to control how your personal information is being used by Taojaa to serve
                            you within and outside Taojaa.
                        </p>
                        <p className="mb-2 vn-guides-desc">
                            We want to offer you choices about the different ways we collect, use, or share your personal information. These choices are described below.
                        </p>
                        <p className="mb-2 vn-guides-desc">
                            <b>Marketing Preferences</b> <br />

                            When we are sending marketing communications to you, we use the following standards: <br />

                            <ul className="vn-guides-ul">
                                <li>We use an opt-in standard for automated phone and text messages, and for sharing personal information with companies outside our corporate family of companies for their use in direct marketing of their products, such as sending you promotions for their products. Opt-in means we will only conduct the activity with your affirmative consent.</li>
                                <li>We use an opt-out standard for email, app notifications, and postal mail. Opt-out means we may contact you, but will stop upon your request (as described below).</li>
                            </ul>
                        </p>
                        <p className="mb-2 vn-guides-desc">
                            Please know that, even if you opted out of receiving marketing communications from us, we may still contact you for transactional or informational purposes. Some examples are contacts for customer service, product information, service or reminder notices, or recalls. We may also need to contact you with questions or information regarding your order.
                        </p>

                        <p className="mb-2 vn-guides-desc">
                            <b>Mobile Location Settings</b> <br />
                            Some of our mobile services use your device’s location information. You can adjust the location settings of your mobile device at any time to control whether your device communicates this information. You will also need to adjust your device’s Bluetooth settings to completely disable the collection of Bluetooth location-related data. See your device instructions to learn more about these settings. In addition, we may offer you controls on our use of your mobile location information. See “What Types of Information Do We Collect?” above for more information on the types of location information we collect.
                        </p>
                        <p className="mb-2 vn-guides-desc">
                            <b>Device Camera and Microphone</b> <br />
                            Some of the features on our websites and mobile services may require access to the camera or microphone of your computer or mobile device. You must give your permission before we access your device’s camera or microphone, and you can adjust the settings of your device at any time to control access to your camera or microphone. See “What Types of Information Do We Collect?” above for more information.
                        </p>
                    </div>

                    <div id="privacy-policy-disclosure" className="vn-guides mt-5 pt-5">
                        <h1 className="">Privacy Policy Disclosure</h1>
                        <p className="mb-2 vn-guides-desc">
                            Thank you for using our services. This Privacy Policy is designed to help you understand how we collect, use, disclose, and safeguard your information when you use our website or any associated services (collectively referred to as "Services"). By accessing or using our Services, you agree to the terms outlined in this Privacy Policy.
                        </p>

                        <p className="mb-2 vn-guides-desc">
                            <b>Information Collection and Use</b> <br />
                            We may collect various types of information from you when you use our Services, including personal information such as your name, email address, and any other information you voluntarily provide. Additionally, certain information may be automatically collected through cookies and similar technologies as you browse our website.
                        </p>

                        <p className="mb-2 vn-guides-desc">
                            <b>Third-Party API Services</b> <br />
                            To enhance the functionality of our Services, we may utilize third-party API services. These services may involve the transfer of information between our platform and the third-party's servers. It is important to note that any information transferred to these third-party API services is subject to their respective privacy policies. We've also added below links to our respective third-party API services User Data Policy.
                            <ul className='mt-3'>
                                <li><a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes' target='_blank' rel='noreferrer'>Google API Services User Data Policy</a></li>
                                <li><a href='https://policy.pinterest.com/en/privacy-policy' target='_blank' rel='noreferrer'>Pinterest</a></li>
                            </ul>
                        </p>

                        <p className="mb-2 vn-guides-desc">
                            <b>Compliance with Third-Party API Data Privacy Policies</b> <br />
                            We take your privacy seriously and are committed to ensuring that your information is handled securely. When we transfer information to third-party API services, we adhere to their data privacy policies. It is your responsibility to review and understand the privacy policies of these third-party services, as they govern the collection, use, and disclosure of your information once it is transferred.
                        </p>

                        <p className="mb-2 vn-guides-desc">
                            <b>Security Measures</b> <br />
                            We implement industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                        </p>

                        <p className="mb-2 vn-guides-desc">
                            <b>Your Choices</b> <br />
                            You have the right to choose whether or not to provide us with certain information. However, choosing not to provide certain information may limit your ability to use specific features of our Services.
                        </p>

                        <p className="mb-2 vn-guides-desc">
                            <b>Updates to Privacy Policy</b> <br />
                            We reserve the right to update this Privacy Policy at any time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this Privacy Policy periodically.
                        </p>

                        <p className="mb-2 vn-guides-desc">
                            <b>Contact Us</b> <br />
                            If you have any questions, concerns, or requests regarding this Privacy Policy or the protection of your information, please contact us at info@taojaa.com.
                        </p>

                        <p className="mb-2 vn-guides-desc">
                            By continuing to use our Services, you acknowledge that you have read and understood this Privacy Policy, including the disclosure related to third-party API services,
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy