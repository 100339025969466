import React, { createContext, useEffect } from 'react'

export const AppContext = createContext(null)

const AppProvider = ({ children }) => {

    const footerText = "Helping businesses connect with customers from anywhere, at any time.";

    useEffect(() => {

        var listener = window.addEventListener('scroll', function () {
        });

        return window.removeEventListener("scroll", listener);
    }, [])

    return (
        <AppContext.Provider value={{ footerText }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider