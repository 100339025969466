import { Image } from "react-bootstrap";
import { AddBankButton, AddCurrencyButton, BankAccountSettings, BankInfoModal, CurrencySettings, DashboardView, PaymentSetupMenu, SelectAndSaveCurrency } from "../../../../assets";
import Help from "../Help";
const HowToSetupMultiCurrency = () => {
    return (
        <div className="container mb-5">
            <div className="row">
                <div className="col-lg-3 desktop relative">
                    <ul className="vn-guides-sidebar border-right" id="vn-guides-sidebar">
                        <li className="text-muted vn-item active mb-3 fw-bold">
                            <a href="#intro" className="vc-click">
                                Enable Multicurrency Options
                            </a>
                        </li>
                        <li className="text-muted vn-item mb-3">
                            <a href="#step-1" className="vc-click">
                                Step 1: Login to Dashboard
                            </a>
                        </li>
                        <li className="text-muted vn-item mb-3">
                            <a href="#step-2" className="vc-click">
                                Step 2: Payment Setup
                            </a>
                        </li>
                        <li className="text-muted vn-item mb-3">
                            <a href="#step-3" className="vc-click">
                                Step 3: Currency Settings
                            </a>
                        </li>
                        <li className="text-muted vn-item mb-3">
                            <a href="#step-4" className="vc-click">
                                Step 4: Add Currency
                            </a>
                        </li>
                        <li className="text-muted vn-item mb-3">
                            <a href="#step-5" className="vc-click">
                                Step 5: Add Bank Accounts
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-7">
                    <div id="intro" className="vn-guides pt-5">
                        <h1 className="">Enable Multi-Currency payment options.</h1>
                    </div>

                    <div id="step-1" className="vn-guides">
                        <p className="mt-2 mb-4 vn-guides-desc">
                            <b>Step 1:</b> Log in to your Taojaa account, You'll land on your Dashboard.
                        </p>

                        <p className="mt-4 mb-4 vn-guides-desc">
                            <Image src={DashboardView} alt="" fluid />
                        </p>
                    </div>

                    <div id="step-2" className="vn-guides">
                        <p className="mt-2 mb-4 vn-guides-desc">
                            <b>Step 2:</b> Scroll down on the sidebar menu and click on the “Account Settings” dropdown, then click on "Payment Setup"
                        </p>

                        <p className="mt-4 mb-4 vn-guides-desc">
                            <Image src={PaymentSetupMenu} alt="" fluid />
                        </p>
                    </div>

                    <div id="step-3" className="vn-guides">
                        <p className="mt-2 mb-4 vn-guides-desc">
                            <b>Step 3:</b> On the 'Payment Setup' page, find ‘Currency Settings' section. This is where you configure your multi-currency options.
                        </p>

                        <p className="mt-4 mb-4 vn-guides-desc">
                            <Image src={CurrencySettings} alt="" fluid />
                        </p>
                    </div>
                    <div id="step-4" className="vn-guides">
                        <p className="mt-2 mb-4 vn-guides-desc">
                            <b>Step 4:</b> On the "Currency Settings" card, click on the "Add" button.
                        </p>

                        <p className="mt-4 mb-4 vn-guides-desc">
                            <Image src={AddCurrencyButton} alt="" fluid />
                        </p>
                        <p className="vn-guides-desc"> A modal will pop up and with an option to select from the list of currencies the currency you want to add for your business.
                            <br /> These will be currencies you accept for your products and services. <br /> Select a currency and click <b>"Add"</b> to save.</p>
                        <p className="mt-4 mb-4 vn-guides-desc">
                            <Image src={SelectAndSaveCurrency} alt="" fluid />
                        </p>
                    </div>
                    <div id="step-5" className="vn-guides">
                        <p className="mt-2 mb-4 vn-guides-desc">
                            <b>Step 5: Add Bank Account details</b>
                            <br />
                            <ul>
                                <li>After adding your preferred currencies, find the "Bank Accounts" section on the "Payment Setup" page.</li>
                            </ul>
                        </p>
                        <p className="mt-4 mb-4 vn-guides-desc">
                            <Image src={BankAccountSettings} alt="" fluid />
                        </p>
                        <p className="mt-4 mb-4 vn-guides-desc">
                            <ul>
                                <li>Click on <b>"Add"</b> button. </li>
                            </ul>
                        </p>
                        <p className="mt-4 mb-4 vn-guides-desc">
                            <Image src={AddBankButton} alt="" fluid />
                        </p>
                        <p className="mt-4 mb-4 vn-guides-desc">
                            <ul>
                                <li>Here, you'll enter the necessary bank account information. This ensures seamless multi-currency transactions, allowing your customers to pay in their preferred currency while funds are transferred to your designated account.</li>
                            </ul>
                        </p>
                        <p className="mt-4 mb-4 vn-guides-desc">
                            <Image src={BankInfoModal} alt="" fluid />
                        </p>
                    </div>
                    <Help />
                </div>
            </div>
        </div>
    );
};

export default HowToSetupMultiCurrency;
