import React from 'react'
import '../../App.scss'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import image1 from '../assets/happy-young-african-ladies-page2-hero.png'
import fbIcon from '../assets/facebook-icon.svg'
import googleIcon from '../assets/google-icon.svg'
import pintrestIcon from '../assets/logos_pinterest.svg'
import instagramIcon from '../assets/instagram-icon.svg'
import fbIconMobile from '../assets/gb-mobile.svg'
import googleIconMobile from '../assets/google-mobile.svg'
import pintrestIconMobile from '../assets/pintrest-mobile.svg'
import instagramIconMobile from '../assets/insta-mobile.svg'
import mainImg2 from '../assets/happy-young-african-ladies-page2-hero-mobile.png'

export default function HeroCard({ title, desp }) {
  return (
    <div
      className=''
      style={{
        background:
          'linear-gradient(133deg, #FEF3FD 25.17%, rgba(213, 246, 251, 0.00) 105.65%)',
      }}
    >
      <Container
        fluid
        className='position-relative wrapper container-fluid '
        style={{
          marginTop: -120,
          background:
            'linear-gradient(133deg, #FEF3FD 25.17%, rgba(213, 246, 251, 0.00) 105.65%)',
        }}
      >
        <Row
          style={{
            paddingTop: 120,
          }}
          className='row hero-mobile align-items-center justify-content-center  container '
        >
          <Col
            sm={10}
            md={12}
            lg={6}
            className=' left-container'
            data-aos='fade-down'
            data-aos-duration='1000'
          >
            <div>
              <h1 className=' hero-card-title'>{title}</h1>
            </div>
            <div className='hero-card-body'>{desp}</div>

            <Button variant='primary' className='bttn btn-primary'>
              Get Started
            </Button>
            <Button variant='primary' className='bttn-mobile btn-primary'>
              Get Started
            </Button>
          </Col>

          <Col
            data-aos='flip-left'
            // sm={10}
            data-aos-duration='1000'
            md={6}
            lg={5}
            className=' img2 right-container'
          >
            <img
              data-aos-duration='1000'
              data-aos='fade-left'
              src={image1}
              alt=''
            />
          </Col>
          <Col
            data-aos='fade-top'
            data-aos-duration='1000'
            sm={12}
            md={12}
            lg={5}
            className='col12 collg-5 img2b right-container'
          >
            <img
              data-aos-duration='1000'
              data-aos='fade-left'
              src={mainImg2}
              alt=''
            />
          </Col>
        </Row>

        <div className='facebook position-absolute'>
          <img alt='' src={fbIcon} />
        </div>
        <div className='logos_pinterest position-absolute'>
          <Image alt='' src={pintrestIcon} />
        </div>
        <div className='google position-absolute'>
          <Image alt='' src={googleIcon} />
        </div>
        <div className='instagram position-absolute'>
          <Image alt='' src={instagramIcon} />
        </div>
        {/* mobile */}
        <div className='facebook2 position-absolute'>
          <img alt='' src={fbIconMobile} />
        </div>
        <div className='logos_pinterest2 position-absolute'>
          <Image alt='' src={pintrestIconMobile} />
        </div>
        <div className='google2 position-absolute'>
          <Image alt='' src={googleIconMobile} />
        </div>
        <div className='instagram2 position-absolute'>
          <Image alt='' src={instagramIconMobile} />
        </div>
      </Container>
    </div>
  )
}
