import { InvoiceDetails } from "../../../../assets";
import { Image } from "react-bootstrap";
import Help from "../Help";
const HowToSendInvoiceViaEmail = () => {
  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-lg-3 desktop relative">
          <ul className="vn-guides-sidebar border-right" id="vn-guides-sidebar">
            <li className="text-muted vn-item active mb-3 fw-bold">
              <a href="#intro" className="vc-click">
                Sending Invoice Via Email
              </a>
            </li>
            <li className="text-muted vn-item mb-3">
              <a href="#step-1" className="vc-click">
                Step 1: Send Button
              </a>
            </li>
          </ul>
        </div>
        <div className="col-lg-7">
          <div id="intro" className="vn-guides pt-5">
            <h1 className="">Send invoice via email.</h1>
          </div>

          <div id="step-1" className="vn-guides">
            <p className="mt-2 mb-2 vn-guides-desc">
              Step 1: Upon saving the invoice, you will be directed to the
              invoice details page. From here, you can easily send the invoice
              to your customer's email address associated with their information
              by clicking the "Send" button.
            </p>

            <p className="mt-4 mb-2 vn-guides-desc">
              <Image src={InvoiceDetails} alt="" fluid/>
            </p>
          </div>
          <Help />
        </div>
      </div>
    </div>
  );
};

export default HowToSendInvoiceViaEmail;
