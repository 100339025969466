import { Col, Container, Row, Carousel } from "react-bootstrap";
import { InstagramImg } from "../../assets";
import { IoMdArrowForward } from "react-icons/io";

const SalesChannels = ({ data, section, order }) => {

    const { title, paragraph, benefits, images, images2 } = data;

    return (
        <section className={`${section} overflow-hidden pb-5`}>
            <Container>
                <Row className="align-items-center justify-content-around">
                    <Col lg={6} xxl={6} className={section === "grow" ? `${order} text-side text-lg-start order-1 offset-lg-1` : `${order} text-side text-lg-start order-1`}
                        data-aos="fade-up" data-aos-duration="500">
                        <h2 class="mb-4 display-6 fw-bolder">{title}</h2>
                        <p className="col-12" >{paragraph}</p>
                        <ul className="ms-3 px-0 mb-5">
                            {benefits.map((benefit, index) => (
                                <li key={index}>{benefit}</li>
                            ))}
                        </ul>
                        <a variant="primary" href={section === "grow" ? "/grow" : "/sell"} id="signup-btn" className="fw-bolder mt-5">
                            Learn More <IoMdArrowForward size={18} />
                        </a>
                    </Col>
                    <Col lg={5} xxl={5} className={section === "grow" ? "img-side" : "img-side offset-lg-1"}>
                        <div>
                           
                              <img src={`https://res.cloudinary.com/dp7quxpsq/image/upload/v1721815493/Authapp-Images/SELL_ANYWHERE_SECTION_vf90oe.png`} alt="hero" className="img-fluid ms-lg-4 shadow-none" data-aos-duration="500" data-aos="fade-left" />
                            <div className={section === "grow" ? "d-block" : "d-none"}>
                                <img src={images2} alt="/" className="img-fluid side-image" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default SalesChannels;