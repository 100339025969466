import { Badge, Button, Card, Col, Nav, Row } from "react-bootstrap";

import { BillingCycles } from "../data";
import { ChevronRight } from "react-bootstrap-icons";
import { PricingData } from "../data";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const choosePlan = (cta, navigate) => {
  const planName = cta.split(" ")[1];
  if (
    planName === "Starter" ||
    planName === "Professional" ||
    planName === "Premium"
  ) {
    localStorage.setItem("selectedPlan", planName);
  }

  navigate("/auth/create-account");
};

const PricingCard = ({
  title,
  desc,
  btnType = "primary",
  plan,
  icon = false,
  prevTitle,
  cta = `Try for free`,
  features,
  billingCycle,
}) => {
  const navigate = useNavigate();
  const calcDiscount = (plan) => {
    if (plan?.discount) {
      return (
        (plan?.price | plan) - (plan.discount / 100) * (plan?.price | plan)
      );
    }
    return plan?.price | plan;
  };
  return (
    <Col
      xs={12}
      sm={6}
      md={6}
      lg={6}
      xl={3}
      className="d-flex align-items-stretch"
    >
      <Card className="shadow-sm border-0 rounded-2 px-5 py-5 w-100 position-relative" bg="light">
        {plan?.discount ? <Badge bg="warning" className="top-0 end-0 position-absolute d-flex justify-content-center align-items-center discount-badge" pill style={{ width: 50, height: 50 }}><span className="fs-5">{plan.discount}%</span></Badge> : ""}
        <div className="d-flex gap-3">
          {icon}
          <div className="position-relative">
            <h4 className="text-primary-emphasis">{title}</h4>
            {title === 'Professional' && <span cla></span>}
            <p style={{ fontSize: 14 }} className="">
              {desc}
            </p>
          </div>
        </div>
        <div className="text-center mt-3 mb-2">
          {plan?.discount ? (
            <del className="text-black-50 text-opacity-50 h4">
              <sup style={{ top: -2 }}>&#x20A6;</sup>
              {plan?.price | plan}
            </del>
          ) : (
            ""
          )}
          <h2>
            <sup style={{ fontSize: 16, top: -14 }}>&#x20A6;</sup>
            {typeof calcDiscount(plan) === "number"
              ? calcDiscount(plan).toLocaleString()
              : calcDiscount(plan)}
          </h2>
          <small className="text-primary py-4 pt-3 d-block fw-semibold" >{`Billed every ${BillingCycles.find(cycle=>cycle.key === billingCycle).duration}`}</small>
        </div>
        <div className="mb-5">
          {prevTitle ? <p className="small">{`Everything on ${prevTitle}, plus:`}</p> : null}
          <ul className="benefit-list bg-transparent px-0">
            {features?.map((feature, index) => (
              <li className="border-0" key={index}>
                <small style={{ fontSize: 14 }}>{feature}</small>
              </li>
            ))}
          </ul>
        </div>
        <div className="d-grid gap-2 mt-auto">
          <Button
            variant={btnType}
            size="lg"
            className="fw-bold"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => choosePlan(cta, navigate)}
          >
            {cta}
          </Button>
          <Button
            variant="link"
            className="link-dark text-decoration-none"
            href="/pricing"
            size="md"
          >
            Compare Plan <ChevronRight />
          </Button>
        </div>
      </Card>
    </Col>
  );
};

const PricingCards = ({ billingCycle, handleChange }) => {
  const FilteredPricingData = useMemo(() => {
    return PricingData.filter((data) => data.plans[billingCycle] !== undefined);
  }, [billingCycle]);
  return (
    <>
      <Row
        className="mt-5 pt-5 justify-content-center text-center"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <Col xl={6} lg={8} xs={12}>
          <h1 className="mb-4 fs-1 fw-bolder">Find the right plan for your business</h1>
          <p>
            Discover the right plan for your business. Select a pricing plan for
            your business below to find a solution that fits your needs.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center my-4">
        <Col xl={6} lg={8} xs={12}>
          <Nav variant="pills" fill defaultActiveKey={billingCycle} className="bg-taoja-light-primary rounded-3">
            {BillingCycles.map((cycle) => (
              <Nav.Item key={cycle.key}>
                <Nav.Link
                  onClick={() => handleChange(cycle.key)}
                  eventKey={cycle.key}
                >
                  {cycle.title}{" "}
                  {
                    cycle.indicator ? <Badge bg="black" className="rounded-pill">{cycle.indicator}</Badge> : ""
                  }
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
      </Row>
      <Row className="py-5 justify-content-center gy-5 gy-xl-0">
        {FilteredPricingData.map((data, index) => (
          <PricingCard
            key={index}
            {...data}
            plan={data.plans[billingCycle]}
            billingCycle={billingCycle}
          />
        ))}
      </Row>
    </>
  );
};

PricingCard.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  benefits: PropTypes.arrayOf(PropTypes.string),
  btnType: PropTypes.oneOf(["primary", "outline-primary"]).isRequired,
  plans: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  plan: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.objectOf({ price: PropTypes.number, discount: PropTypes.number })
      .isRequired,
  ]),
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  prevTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  cta: PropTypes.string,
};

PricingCards.propTypes = {
  billingCycle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleChange: PropTypes.func,
};

export default PricingCards;
