import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Banner2 = ({ data }) => {
    return (
        <section style={{ backgroundColor: '#8A50FC' }} className='mb-5'>
            <Container className='py-5'>
                <Row>
                    <Col lg={8}>
                        <Col>
                            <h1 className='text-white fw-bolder section-title' data-aos="fade-down" data-aos-duration="1000">{data.banner2.title}</h1>
                            <p className='text-white' data-aos="fade-right" data-aos-duration="1000">{data.banner2.caption}</p>
                        </Col>
                    </Col>
                    <Col lg={12} className='mt-4' data-aos="fade-up" data-aos-duration="1000">
                        <Col lg={12} className='p-3 bg-white rounded-3'>
                            {data.banner2.tips.map((tip, index) =>
                                <Col key={index} className='mb-5'>
                                    <h4 data-aos="fade-up" data-aos-duration="1000">{tip.title}</h4>
                                    <p data-aos="fade-up" data-aos-duration="1000">{tip.caption}</p>
                                </Col>
                            )}
                        </Col>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Banner2