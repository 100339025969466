import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';
import './App.scss';

import $ from 'jquery';
import axios from 'axios';

import AppProvider from './contexts/AppContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

window.token = localStorage.getItem("token");
axios.defaults.baseURL = process.env.REACT_APP_AUTH_ENDPOINT;
const token = localStorage.getItem("token");

axios.defaults.headers.common = {
  'Authorization': 'Bearer ' + token,
  'Accept': 'application/json'
};

$.ajaxSetup({
  headers: {
    'Authorization': 'Bearer ' + token
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
      <AppProvider>
        <App />
      </AppProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
