import React, { useLayoutEffect, useState } from "react";
import { ReactComponent as Loginn } from '../assets/Loginn.svg';
import Logo from '../Shared/Logo';
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";

const VerifyEmail = () => {

    document.title = 'Verify Account - Taojaa';

    const { hash } = useParams();
    const [searchParams] = useSearchParams();
    const isStaffAccount = searchParams.get('staff_account');

    const [isLoading, setIsLoading] = useState(true);
    const [verified, setVerified] = useState(false);
    const [message, setMessage] = useState('Verifying account, please wait...');

    useLayoutEffect(() => {
        verifyEmail()
    }, [])

    async function verifyEmail() {

        try {

            const response = await axios.get(`/account/verify/${hash}${isStaffAccount ? '?is_staff_account=1' : ''}`);
            if (response.data.success) {

                if (isStaffAccount) {
                    return window.location = `/auth/reset-password?reset_id=${response.data.code}`;
                }

                setMessage(response.data.message);
                setVerified(true);
            }

        } catch (error) {
            if (error.response) {
                setMessage(error.response.data.message);
            } else {
                setMessage('Unable to verify account, please try again')
            }
        }

        setIsLoading(false);
    }

    return (
        <div className='col-md-12'>
            <div className='row tj-everything'>
                <div className='col-md-6'>
                    <Logo />
                    <div className='d-flex justify-content-center mt-4'>
                        <div className='tj-box' style={{ margin: '150px auto' }}>
                            {!isLoading ? (
                                <>
                                    {verified ? (
                                        <>
                                            <div className='text-center alert alert-success mt-3 mb-3'>
                                                <p className="m-0 p-0">{message}</p>
                                            </div>
                                            <button className='tj-box1' onClick={() => window.location = '/auth/login'}>
                                                Continue
                                            </button>
                                        </>
                                    ) : (
                                        <div className='text-center alert alert-danger mt-3 mb-3'>
                                            <p className="m-0 p-0">{message}</p>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className='text-center alert alert-info mt-3 mb-3'>
                                    <p className="m-0 p-0">{message}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='col-md-6 desktop'>
                    <Loginn className='tj-right' />
                </div>
            </div >
        </div>
    )

}

export default VerifyEmail;