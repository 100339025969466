import React, { useEffect } from "react";
import Hero from "./Hero.jsx";
import TestimonialSlides from "./TestimonialSlides.jsx";
import { useParams } from "react-router-dom";
import { deleteCookie, setCookie } from "wearslot-dev-utils";
import { SalesChannel, ManageSales} from "../../data/index.jsx";
import SalesChannels from "./SalesChannels.jsx";
import Manage from "./Manage.jsx";

const Home = () => {

    const { affiliate, ref_id } = useParams();

    useEffect(() => {

        if (affiliate !== undefined) {
            deleteCookie("ref_id", 'taojaa.com');
            return setCookie("affiliate", affiliate, 1, 'taojaa.com')
        }

        if (ref_id !== undefined) {
            deleteCookie("affiliate", 'taojaa.com');
            return setCookie("ref_id", ref_id, 1, 'taojaa.com')
        }
    }, [affiliate, ref_id]);

    return (
        <>
            <Hero />
            <SalesChannels data={SalesChannel} section="sales" order="order-lg-0" />
            <Manage data={ManageSales} section="sell" order="order-lg-0"/>
            <TestimonialSlides />
        </>
    );
};

export default Home;
