import React from 'react'
import { useParams } from 'react-router-dom'
import { GuidesList } from '../../../data'

const Guide = () => {

    const { slug } = useParams()
    const guide = GuidesList.filter((guide) => guide.link === slug);
    if (guide.length === 0) {

    }

    document.title = guide[0].title + " - Taojaa - Connecting businesses to customers from anywhere at anytime.";

    return (
        <>{guide[0].element}</>
    )
}

export default Guide