import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Benefits = ({ data }) => {
    return (
        <section style={{backgroundColor: '#F9FAFB'}}>
            <Container className='pt-5 pb-5'>
                <Col lg={8}>
                    <h1 className='fw-bolder section-title' data-aos="fade-right" data-aos-duration="1000">{data.benefits.heading}</h1>
                    <Col lg={7} data-aos="fade-right" data-aos-duration="1000">
                        <p style={{ fontSize: 20 }}>{data.benefits.subheading}</p>
                    </Col>
                </Col>
                <Row>
                    {data.benefits.values.map((benefit, index) =>
                        <Col lg={6} key={index} data-aos="fade-up" data-aos-duration="1000" style={{ marginTop: ((index % 2) > 0 && window.innerWidth > 480) ? -70 : 0 }}>
                            <Col lg={12} className='pt-5 benefit-card mb-5 rounded-5'>
                                <Col className='px-3'>
                                    <h4 className='text-white'>{benefit.title}</h4>
                                    <Col lg={8}>
                                        <p className='text-white'>{benefit.caption}</p>
                                    </Col>
                                </Col>
                                <img src={benefit.image} alt={benefit.title} />
                            </Col>
                        </Col>
                    )}
                </Row>
            </Container>
        </section>
    )
}

export default Benefits