import React from 'react'
import { Col, Row } from 'react-bootstrap'

const Banner = ({ data }) => {
    return (
        <section style={{ backgroundColor: '#8A50FC' }}>
            <Col lg={12} className='pt-5 mr-0'>
                <Row>
                    <Col lg={6} className='px-3 py-5'>
                        <Col className={`${window.innerWidth > 480 ? 'ps-5 ms-5' : 'text-center'}`}>
                            <h1 className='text-white fw-bolder section-title' data-aos="fade-right" data-aos-duration="1000">{data.banner.title}</h1>
                            <p className='text-white' data-aos="fade-right" data-aos-duration="1000">{data.banner.caption}</p>
                        </Col>
                    </Col>
                    <Col lg={6} data-aos="fade-up" data-aos-duration="1000">
                        {window.innerWidth > 480
                            ? <img src={data.banner.image} alt={data.banner.title} width={'100%'} />
                            : <img src={data.banner.mobileimage} alt={data.banner.title} width={'100%'} />}
                    </Col>
                </Row>
            </Col>
        </section>
    )
}

export default Banner