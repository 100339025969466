import { Button, Col, Container } from "react-bootstrap";

const FootBanner = () => {
  return (
    <div className="py-5 bg-white">
      <Container>
        <Col
          xs={12}
          lg={10}
          data-aos="zoom-in" data-aos-duration="1000"
          className="mx-auto px-4 pt-5 py-5 text-center text-lg-start bg-taoja-light-primary d-flex align-items-center justify-content-center justify-content-lg-start rounded-4"
        >
          <div className="py-5 px-5 mx-lg-5">
            <h1 className="display-5 fw-bold text-body-emphasis">Connect with your customers from anywhere at <span className="text-primary">any time</span></h1>
            <p className="mb-4">
              Taojaa provides additional tools that will help grow your business, making it easier to manage around your
              odd schedules.
            </p>
            <div className="d-grid gap-2 d-sm-flex justify-content-center justify-content-lg-start">
              <Button variant="primary px-5" href="/auth/create-account">Sign up</Button>
            </div>
          </div>
        </Col>
      </Container>
    </div>
  );
};

export default FootBanner;
