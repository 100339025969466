import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { ProductData as data } from "../../data";

const Products = () => {
  const { slug } = useParams();

  return (
    <div>
      <Container className={data[slug].image ? "py-5" : "pt-5"}>
        <div
          className="px-3 pt-5 py-5 text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1 className="display-4 fw-bold text-body-emphasis">
            {data[slug].title}
          </h1>
          <p className="mb-4">{data[slug].caption}</p>
          <div className={`d-grid gap-2 d-sm-flex justify-content-sm-center ${data[slug].image ? 'mb-5' : ''}`}>
            <Button variant="primary px-4 fw-bold" href="/auth/create-account">
              Get Started
            </Button>
          </div>
          {data[slug].image ?
            <div className="px-5 mt-auto">
              <Image
                src={data[slug].image}
                alt=""
                fluid
                className="d-none d-md-block"
                data-aos="zoom-in"
                data-aos-duration="1000"
              />
            </div> : null}
        </div>
      </Container>

      <Container className={ data[slug].caption2 ? "py-5" : "px-5 py-0"}>
        {data[slug].caption2 ? (
          <Col
            xs={12}
            lg={10}
            className="mx-auto px-4 pt-5 py-5 text-center bg-taoja-light-primary d-flex align-items-center justify-content-center rounded-4"
          >
            <div
              className="py-5 px-5"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <h1 className="display-4 fw-bold text-body-emphasis">
                {data[slug].title2}
              </h1>
              <p className="mb-4">{data[slug].caption2}</p>
              <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                <Button
                  variant="primary px-4 fw-bold"
                  href="/auth/create-account"
                >
                  Get Started
                </Button>
              </div>
            </div>
          </Col>
        ) : null}
        <Col lg={10} className="mx-auto">
          <Row xs={1} sm={2} md={3} className="g-4 py-5">
            {data[slug].features?.length > 0
              ? data[slug].features.map((feature) => (
                <Col className="d-flex align-items-start">
                  {feature.icon}

                  <div>
                    <h5 className="fw-bold mb-0 fs-5 text-bod-emphasis">
                      {feature.title}
                    </h5>
                    <p className="small">{feature.description}</p>
                  </div>
                </Col>
              ))
              : ""}
          </Row>
        </Col>
      </Container>
    </div>
  );
};

export default Products;
