import { Carousel, Col, Container, Image, Row } from "react-bootstrap";
import { TeamsImg, OfferImg, VisionImg, MissionImg } from "../../assets";

const AboutUs = () => {
  return (
    <>
      <Container className="mt-5 py-5">
        <Col xl={10} lg={10} xs={12} className="mx-auto text-center pb-5">
          <h1 className="mb-4 display-6 fw-bolder">Our Brand <span className="text-primary">Story</span></h1>
          <p>The name <span className="text-primary">"Taojaa"</span> has its roots in the Yoruba language, derived from the words <span className="text-primary">"Ta"</span> and <span className="text-primary">"Ojaa"</span>, meaning "sell market." This unique term not only serves as a traditional greeting exchanged among business owners, customers, and the community but also carries the essence of bestowing blessings for prosperity and success in their entrepreneurial pursuits.</p>
          <p>In Africa, local business owners and SMEs face significant challenges in effectively managing their online businesses due to a lack of <span className="text-primary">accessible, affordable, and adaptable</span> tools that meet their specific needs. In response, <span className="text-primary">Taojaa</span> provides a tailored solution that enables entrepreneurs to overcome these challenges and gain access to the essential resources they need to manage their businesses successfully.</p>
          <p> Founded in 2022, <span className="text-primary">Taojaa</span> is a tech startup utilizing seamless Software-as-a-Service (SaaS) technology to help African SMEs connect their businesses with their customers from anywhere, at any time.</p>
          <p>We provide a <span className="text-primary">smart, affordable, and easy-to-use</span> business management solution, equipping business owners with the necessary tools to efficiently manage their operations, track finances, and connect with customers online. Moreover, <span className="text-primary">Taojaa</span> helps businesses become more organized with tools for inventory, sales tracking, and marketing support.</p>
          <p>Hence, the name - <span className="text-primary">Taojaa</span>, suggests our brand promise: to empower local business owners and SMEs with the right business management solution to grow their businesses and thrive.</p>
        </Col>
        <Col xl={8} lg={10} xs={12} className="mx-auto pb-5">
          <Image src={TeamsImg} fluid className="rounded-4" />
        </Col>

        <Row className="py-5 my-5 gy-5 align-items-center justify-content-around">
          <Col md={6} lg={5}>
            <h3 className="text-primary fs-2 fw-bold mb-4">What we offer</h3>
            <p className="lh-lg">
              Our platform offers a wide range of features to simplify business management, ranging from financial
              management and customer tracking to inventory, sales, and marketing tools. We are dedicated to
              providing an affordable and easy-to-use solution that helps business owners become more organized
              while growing their customer base.
            </p>
          </Col>
          <Col md={6} lg={5}>
            <Image src={OfferImg} fluid className="rounded-4" />
          </Col>
        </Row>
        <Row className="py-5 my-5 gy-5 align-items-center justify-content-around">
          <Col md={6} lg={5}>
            <Image src={VisionImg} fluid className="rounded-4" />
          </Col>
          <Col md={6} lg={5}>
            <h3 className="text-primary fs-2 fw-bold mb-4">Our Vision</h3>
            <p className="lh-lg">Our vision is to become the most reliable and efficient online SaaS platform connecting businesses to more possibilities.</p>
          </Col>
        </Row>
        <Row className="py-5 my-5 gy-5 align-items-center justify-content-around">
          <Col md={6} lg={5}>
            <h3 className="text-primary fs-2 fw-bold mb-4">Our Mission</h3>
            <p className="lh-lg">
              To help local businesses and SMEs connect their businesses with their customers from anywhere, at any time
            </p>
          </Col>
          <Col md={6} lg={5}>
            <Image src={MissionImg} fluid className="rounded-4" />
          </Col>
        </Row>

        <Row className="py-5 my-5 gy-5 align-items-center justify-content-around">
          <Col md={6} lg={12} className="justify-content-start">
            <h3 className="text-primary fs-2 fw-bold ms-5">Our Values</h3>
          </Col>
          <Col xs={12}>
            <Carousel fade variant="dark" controls={false}>
              <Carousel.Item className="bg-body">
                <Row className="py-5 gy-5 align-items-center justify-content-around">
                  <Col md={6} lg={5}>
                    <h3 className="text-primary fs-2 fw-bold mb-4">
                      Simplicity
                    </h3>
                    <p className="lh-lg">
                      We simpify the process of managing an online business for business owners to help them focus on growing their business.
                    </p>
                  </Col>
                  <Col md={6} lg={5}>
                    <Image src={MissionImg} fluid className="rounded-4" />
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item className="bg-body">
                <Row className="py-5 gy-5 align-items-center justify-content-around">
                  <Col md={6} lg={5}>
                    <h3 className="text-primary fs-2 fw-bold mb-4">
                      Flexibility
                    </h3>
                    <p className="lh-lg">
                      We help businesses connect with customers, from anywhere, at any time, making it convenient to manage around odd schedules.
                    </p>
                  </Col>
                  <Col md={6} lg={5}>
                    <Image src={MissionImg} fluid className="rounded-4" />
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item className="bg-body">
                <Row className="py-5 gy-5 align-items-center justify-content-around">
                  <Col md={6} lg={5}>
                    <h3 className="text-primary fs-2 fw-bold mb-4">
                      Integrity
                    </h3>
                    <p className="lh-lg">
                      We provide a secure and reliable platform for SMEs to manage their business safely and smartly.
                    </p>
                  </Col>
                  <Col md={6} lg={5}>
                    <Image src={MissionImg} fluid className="rounded-4" />
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item className="bg-body">
                <Row className="py-5 gy-5 align-items-center justify-content-around">
                  <Col md={6} lg={5}>
                    <h3 className="text-primary fs-2 fw-bold mb-4">
                      Quality
                    </h3>
                    <p className="lh-lg">
                      We ensure top-tier quality in every aspect of business management, offering seamless experiences and robust tools that meet the highest standards of excellence.
                    </p>
                  </Col>
                  <Col md={6} lg={5}>
                    <Image src={MissionImg} fluid className="rounded-4" />
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item className="bg-body">
                <Row className="py-5 gy-5 align-items-center justify-content-around">
                  <Col md={6} lg={5}>
                    <h3 className="text-primary fs-2 fw-bold mb-4">
                      Impact
                    </h3>
                    <p className="lh-lg">
                      We empower SMEs with smart tools, insightful analytics, and unwavering support, ensuring their businesses not only thrive but also make a significant impact in the market.
                    </p>
                  </Col>
                  <Col md={6} lg={5}>
                    <Image src={MissionImg} fluid className="rounded-4" />
                  </Col>
                </Row>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutUs;
