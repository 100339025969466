import React, { useMemo, useState } from "react";
import {
  Col,
  Container,
  Form,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { GuidesList } from "../../../data";
import GuideTab from "./GuideTab";

const HowToGuides = () => {
  const [searchTerms, setSearchTerms] = useState("");

  // Use useMemo to store the filtered FAQs and update them only when the searchTerms change
  const filteredGuides = useMemo(() => {
    const guideByGroup = {};

    GuidesList.forEach((guide) => {
      if (!guideByGroup[guide.group]) {
        guideByGroup[guide.group] = [];
      }
      guideByGroup[guide.group].push(guide);
    });

    const groupedGuides = Object.keys(guideByGroup).map((group) => ({
      id: group.toLowerCase().replace(/\s+/g, "-"),
      title: group,
      list: guideByGroup[group],
    }));

    groupedGuides.unshift({
      id: "all",
      title: "General",
      list: GuidesList,
    });

    const lowerCaseSearchTerms = searchTerms.toLocaleLowerCase();

    return groupedGuides.map((guideGroup) => ({
      ...guideGroup,
      list: guideGroup.list.filter((guide) => {
        const lowerCaseGuideTitle = guide.title.toLowerCase();
        const lowerCaseGuideDescription = guide.description.toLowerCase();
        const lowerCaseGuideQuerytext = guide.qtext?.toLowerCase() ?? "";
        return (
          lowerCaseGuideTitle.includes(lowerCaseSearchTerms) ||
          lowerCaseGuideDescription.includes(lowerCaseSearchTerms) ||
          lowerCaseGuideQuerytext.includes(lowerCaseSearchTerms)
        );
      }),
    }));
  }, [searchTerms]);

  return (
    <Container className="py-5">
      <div className="text-center pt-4 mb-5">
        <h1 className="">Taojaa Guide: How to Get Started</h1>
        <p>
          Here's everything you need to know about using Taojaa to manage your
          activities better and improve your business results.
        </p>
        <Col sm={8} className="mx-auto my-5">
          <Form
            className="d-flex position-relative"
            onSubmit={(e) => e.preventDefault()}
          >
            <Form.Control
              type="search"
              placeholder="Search"
              aria-label="Search"
              className="ps-5"
              size="lg"
              onChange={(e) => setSearchTerms(e.target.value)}
            />
            <div className="search-icon">
              <Search />
            </div>
          </Form>
        </Col>
      </div>
      <Tabs
        defaultActiveKey={filteredGuides[0].id}
        id="guideTabs"
        className="mb-5"
        fill
        variant="pills"
      >
        {filteredGuides.map((guideGroup) => (
          <Tab
            title={guideGroup.title}
            eventKey={guideGroup.id}
            key={guideGroup.id}
          >
            {guideGroup.list.length ? (
              <Row>
                <GuideTab list={guideGroup.list} />
              </Row>
            ) : (
              <div className="py-4">
                <p>
                  No matching Guides for the search term{" "}
                  <span className="text-primary fw-bold">"{searchTerms}"</span>{" "}
                  found.
                </p>
              </div>
            )}
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
};

export default HowToGuides;
