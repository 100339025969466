import { Col, Container, Row } from "react-bootstrap";
import { IoMdArrowForward } from "react-icons/io";

const Manage = ({ data, section, order }) => {
  return (
    <section className={`${section} overflow-hidden pb-5`}>
      <Container>
        <Row>
          <Col lg={5} xxl={5} className="img-side">
            <div>
              <img
                src={`https://res.cloudinary.com/dp7quxpsq/image/upload/v1721815494/Authapp-Images/MANAGE_SECTION_iduhfj.png`}
                alt="/"
                className="img-fluid shadow-none"
              />
            </div>
          </Col>
          <Col
            lg={6}
            xxl={6}
            className={`${order} text-side text-lg-start offset-lg-1`}
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <h2 className="mb-4 display-6 fw-bolder">{data.title}</h2>
            <p className="col-12">{data.paragraph}</p>
            <ul className="ms-3 px-0 mb-5">
              {data.benefits.map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>
            <a
              variant="primary"
              href="/manage"
              id="signup-btn"
              className="fw-bolder mt-5"
            >
              Learn More <IoMdArrowForward size={18} />
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Manage;
