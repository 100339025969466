import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './components/Auth/Login.js'
import CreateAccount from './components/Auth/CreateAccount.js'
import BusinessStore from './components/Auth/BusinessStore.js'
import AddLogo from './components/Auth/AddLogo.js'
import ForgotPassword from './components/Auth/ForgotPassword.js'
import ResetPassword from './components/Auth/ResetPassword.js'
import Success from './components/Auth/Success.js'
import React from 'react'
import Terms from './Pages/Legal/Terms.jsx'
import PrivacyPolicy from './Pages/Legal/PrivacyPolicy.jsx'

import 'animate.css'
import AppLayout from './layout/App.jsx'
import Guide from './Pages/Learn/HowToGuides/Guide.jsx'
import UniquePage from './Pages/unique/index.js'
import UniquePage2 from './Pages/unique/UinquePage2.js'
import UinquePage3 from './Pages/unique/UniquePage3.js'
import 'animate.css'
import VerifyEmail from './components/Auth/VerifyEmail.js'
import Error404 from './components/Errors/Error404.js'
import Home from './Pages/Home/index.jsx'
import Pricing from './Pages/Pricing/index.jsx'
import HowToGuides from './Pages/Learn/HowToGuides/index.jsx'
import Product from './Pages/Product/index.jsx'
import Category from './Pages/Category/index.jsx'
import AboutUs from './Pages/Learn/AboutUs.jsx'
import Faqs from './Pages/Learn/Faqs.jsx'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<AppLayout />}>
          <Route path='' element={<Home />} />
          <Route path=':affiliate' element={<Home />} />
          <Route path='/ref/:ref_id' element={<Home />} />
          <Route path='pricing' element={<Pricing />} />
          <Route path='products/:slug' element={<Product />} />
          <Route path='company/about-us' element={<AboutUs />} />
          <Route path='category/:name' element={<Category />} />
          <Route path='resources/how-to-guides' element={<HowToGuides />}/>
          <Route path='resources/faqs' element={<Faqs />} />
          <Route path='guide/:slug' element={<Guide />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/sell' element={<UniquePage />} />
          <Route path='/grow' element={<UniquePage2 />} />
          <Route path='/manage' element={<UinquePage3 />} />
        </Route>
        <Route path='/guide/:slug/text-only' element={<Guide />} />
        <Route path='/auth/login' element={<Login />} />
        <Route path='/auth/create-account' element={<CreateAccount />} />
        <Route path='/auth/store-details' element={<BusinessStore />} />
        <Route path='/auth/add-logo' element={<AddLogo />} />
        <Route path='/auth/success' element={<Success />} />  
        <Route path='/auth/verify/:hash' element={<VerifyEmail />} />
        <Route path='/auth/forgot-password' element={<ForgotPassword />} />
        <Route path='/auth/reset-password' element={<ResetPassword />} />
        <Route path='*' element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
