import { Image } from "react-bootstrap";
import { WithdrawBalanceBtn, WithdrawFundsBtn, WithdrawalModal } from "../../../../assets";
import Help from "../Help";
const HowToMakeWithdrawal = () => {
  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-lg-3 desktop relative">
          <ul className="vn-guides-sidebar border-right" id="vn-guides-sidebar">
            <li className="text-muted vn-item active mb-3 fw-bold">
              <a href="#intro" className="vc-click">
                Send Withdrawal Request
              </a>
            </li>
            <li className="text-muted vn-item mb-3">
              <a href="#step-1" className="vc-click">
                Step 1: Locate Withdraw Money
              </a>
            </li>
            <li className="text-muted vn-item mb-3">
              <a href="#step-2" className="vc-click">
                Step 2: Withdraw Funds
              </a>
            </li>
            <li className="text-muted vn-item mb-3">
              <a href="#step-3" className="vc-click">
                Step 3: Choose Wallet & Withdraw
              </a>
            </li>
          </ul>
        </div>
        <div className="col-lg-7">
          <div id="intro" className="vn-guides pt-5">
            <h1 className="">Send Withdrawal Request on Taojaa</h1>
          </div>

          <div id="step-1" className="vn-guides">
            <p className="mt-2 mb-2 vn-guides-desc">
              Step 1: From the dashboard, locate the "Withdraw Money" option
              under the "My Balance" section.
            </p>

            <p className="mt-4 mb-2 vn-guides-desc">
              <Image src={WithdrawBalanceBtn} alt="" fluid/>
            </p>
          </div>

          <div id="step-2" className="vn-guides">
            <p className="mt-2 mb-2 vn-guides-desc">
              Step 2: Proceed to click on ”withdraw funds”
            </p>

            <p className="mt-4 mb-2 vn-guides-desc">
              <Image src={WithdrawFundsBtn} alt="" fluid/>
            </p>
          </div>

          <div id="step-3" className="vn-guides">
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 3: Choose the wallet you wish to make a withdrawal from and specify the desired withdrawal amount.
            </p>

            <p className="mt-4 mb-2 vn-guides-desc">
              <Image src={WithdrawalModal} alt="" fluid/>
            </p>
          </div>
          <Help />
        </div>
      </div>
    </div>
  );
};

export default HowToMakeWithdrawal;
