import React from 'react'

const Slider = (props) =>
{
    return (
        <div className='d-flex gap-1 align-items-center justify-content-center' style={{ marginTop: `${props.marginTop}rem` }}>
            {
                (props.selected === 1) ? (
                    <div className='d-flex gap-1 align-items-center justify-content-center'>
                        <div className='tj-slider-selected'></div>
                        <div className='tj-slider'></div>
                        <div className='tj-slider'></div>
                    </div>
                ) : (props.selected === 2) ? (
                    <div className='d-flex gap-1 align-items-center justify-content-center'>
                        <div className='tj-slider'></div>
                        <div className='tj-slider-selected'></div>
                        <div className='tj-slider'></div>
                    </div>
                ) : (props.selected === 3) ? (
                    <div className='d-flex gap-1 align-items-center justify-content-center'>
                        <div className='tj-slider'></div>
                        <div className='tj-slider'></div>
                        <div className='tj-slider-selected'></div>
                    </div>
                ) : null
            }

        </div>
    )
}

export default Slider;