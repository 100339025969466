import React from 'react'
import Help from '../Help'

const HowToCreateTaojaaAccount = () => {

    return (
        <div className='container mb-5'>
            <div className='row'>
                <div className='col-lg-3 desktop relative'>
                    <ul className="vn-guides-sidebar border-right" id="vn-guides-sidebar">
                        <li className="text-muted vn-item active fw-bold mb-3"><a href="#intro" className="vc-click">Create and verify account</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#video-guide" className="vc-click">Watch Video Guide</a></li>
                    </ul>
                </div>
                <div className="col-lg-7">
                    <div id="intro" className="vn-guides pt-5">
                        <h1 className="">Create and verify your Taojaa account.</h1>

                        <p className="vn-guides-desc mt-3">
                            Signing up and setting up your account on Toajaa is so easy and in less than 5 minutes your Taojaa account will be ready.
                        </p>
                    </div>

                    <div id="video-guide" className="vn-guides">
                        <p className="mt-2 mb-2 vn-guides-desc">
                            <iframe width="100%" height={window.innerWidth <= 480 ? "207" : "453"} src="https://www.youtube.com/embed/ZvWRY3OE9nM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </p>
                    </div>
                    <Help/>
                </div>
            </div>
        </div>
    )
}

export default HowToCreateTaojaaAccount