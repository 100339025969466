import PropTypes from "prop-types";
import { Card, Col, NavLink } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";

const GuideTab = ({ list }) => {
  return list.map((guide, index) => (
    <Col sm={"col-12"} xl={"col-3"} className="col-md-4 mb-3" key={guide.link}>
      <Card className="py-4 px-3 shadow guide-card h-100">
        <Card.Body className="text-center">
          <Card.Title className="text-primary fs-5 fw-bold">
            {guide.title}
          </Card.Title>
          <Card.Text className="text-black">{guide.description}</Card.Text>
          <div className="">
            <NavLink
              className="btn btn-primary text-white fw-bold"
              href={`/guide/${guide.link}`}
              style={{ display: "inline-block" }}
            >
              View <ArrowRight />{" "}
            </NavLink>
          </div>
        </Card.Body>
      </Card>
    </Col>
  ));
};

GuideTab.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      element: PropTypes.node,
      group: PropTypes.string,
    })
  ),
};

export default GuideTab;
