import React from 'react'
import '../../App.scss'
import { Button, Col, Container, Row } from 'react-bootstrap'

export default function HeroCard2({
  title,
  desp,
  mainImg,
  mainImg2,
  floatingImg2,
}) {
  return (
    <Container
      fluid
      className='position-relative wrapper '
      style={{
        marginTop: -120,
        background:
          'linear-gradient(133deg, #FEF3FD 25.17%, rgba(213, 246, 251, 0.00) 105.65%)',
      }}
    >
      <Row
        style={{
          paddingTop: 120,
        }}
        className='row hero-mobile align-items-center container'
      >
        <Col
          sm={10}
          md={12}
          lg={6}
          className=' left-container aos-init'
          data-aos='fade-down'
          data-aos-duration='1000'
        >
          <div>
            <h1 className=' hero-card-title'>{title}</h1>
          </div>
          <div className='hero-card-body'>{desp}</div>

          <Button variant='primary' className='bttn btn-primary'>
            Get Started
          </Button>
          <Button variant='primary' className='bttn-mobile btn-primary'>
            Get Started
          </Button>
        </Col>

        <Col md={6} lg={5} className=' img2 tablet right-container'>
          <img
            data-aos-duration='1000'
            data-aos='fade-up'
            src={mainImg}
            alt=''
          />
        </Col>
        <Col
          data-aos-duration='1000'
          data-aos='fade-up'
          sm={12}
          md={12}
          lg={5}
          className=' img2b tablet right-container'
        >
          <img
            data-aos-duration='1000'
            data-aos='fade-up'
            src={mainImg2}
            alt=''
          />
        </Col>
      </Row>

      <img
        className='position-absolute floatingimg floatingimg2'
        style={{ bottom: '10px', zIndex: 22 }}
        src={floatingImg2}
        alt=''
      />
    </Container>
  )
}


