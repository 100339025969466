import React from 'react'

import CustomersMenu from "../../../../assets/customer-menu.png";
import AddCustomerButton from "../../../../assets/add-customer-button.png";
import CustomerModal from "../../../../assets/CustomerModal.png";
import Customer from "../../../../assets/Customer.png";
import Help from '../Help';


const HowToAddCustomers = () => {

    return (
        <div className='container mb-5'>
            <div className='row'>
                <div className='col-lg-3 desktop relative'>
                    <ul className="vn-guides-sidebar border-right" id="vn-guides-sidebar">
                        <li className="text-muted vn-item active mb-3 fw-bold"><a href="#intro" className="vc-click">Adding Customers on Taojaa</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-1" className="vc-click">Step 1: Go to Customers</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-2" className="vc-click">Step 2: Add Customer button</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-3" className="vc-click">Step 3: Customer Details</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-4" className="vc-click">Step 4: Done</a></li>
                    </ul>
                </div>
                <div className="col-lg-7">
                    <div id="intro" className="vn-guides pt-5">
                        <h1 className="">Adding customers on Taojaa.</h1>
                    </div>

                    <div id="step-1" className="vn-guides">
                        <p className="mt-2 mb-2 vn-guides-desc">
                            Step 1: Access your dashboard and click on customers on the sidebar menu.
                        </p>

                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={CustomersMenu} alt="" />
                        </p>
                    </div>

                    <div id="step-2" className="vn-guides">
                        <p className="vn-guides-desc mb-2">
                            Step 2: Click on add customer button at the top right corner on the customers page card.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={AddCustomerButton} alt="" style={{ width: "100%" }} />
                        </p>
                    </div>

                    <div id="step-2" className="vn-guides">
                        <p className="vn-guides-desc mb-2">
                            Step 3: Fill in customer information and save.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={CustomerModal} alt="" style={{ width: window.innerWidth <= 480 ? "100%" : 'auto' }} />
                        </p>
                    </div>

                    <div id="step-4" className="vn-guides">
                        <p className="vn-guides-desc mb-2">
                            Step 4: Your customer is successfully added to your customers list.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={Customer} alt="" style={{ width: "100%" }} />
                        </p>
                    </div>
                    <Help/>
                </div>
            </div>
        </div>
    )
}

export default HowToAddCustomers