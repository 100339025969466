import Help from "../Help";
import React from "react";

const HowToSetupCRM = () => {
    return (
        <div className="container mb-5">
            <div className="row">
                <div className="col-lg-3 desktop relative">
                    <ul
                        className="vn-guides-sidebar border-right"
                        id="vn-guides-sidebar"
                    >
                        <li className="text-muted vn-item active mb-3 fw-bold">
                            <a href="#intro" className="vc-click">
                                Send Campaigns using Taojaa CRM
                            </a>
                        </li>
                        {deliverySetupSteps.map((step) => (
                            <li
                                key={step.id}
                                className="text-muted vn-item mb-3"
                            >
                                <a href={`#${step.id}`} className="vc-click">
                                    {step.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="col-lg-7">
                    <div
                        id="intro"
                        className="vn-guides pt-5"
                        style={{ marginBottom: "40px" }}
                    >
                        <h1 className="">
                            How to send Campaigns using Taojaa CRM
                        </h1>
                    </div>

                    {deliverySetupSteps.map((step) => (
                        <div
                            style={{
                                border: "0px solid green",
                                marginBottom: "50px",
                            }}
                            key={step.id}
                            id={step.id}
                            className="mb-20 vn-guides"
                        >
                            {step.description}
                            <p className="mt-3 mb-6 vn-guides-desc">
                                {step.smallImage ? (
                                    <img
                                        src={step.image}
                                        alt=""
                                        style={{
                                            width:
                                                window.innerWidth <= 480
                                                    ? "100%"
                                                    : "auto",
                                        }}
                                    />
                                ) : (
                                    <img
                                        src={step.image}
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                )}
                            </p>
                        </div>
                    ))}
                    <Help />
                </div>
            </div>
        </div>
    );
};

export default HowToSetupCRM;

const deliverySetupSteps = [
    {
        id: "step-1",
        title: "Step 1: Log in to Taojaa store manager dashboard.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 1: Log in to your Taojaa store manager dashboard via{" "}
                <a href="https://www.taojaa.com">www.taojaa.com</a>, click on
                "Marketing" located on the dashboard.
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1709658951/guides/crm-setup/step1_do8iy0.png",
    },
    {
        id: "step-2",
        title: "Step 2: Navigate to Campaigns.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 2: Navigate to "Delivery Setup" in the dropdown menu
                located under "Store Settings.
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1709658952/guides/crm-setup/step2_l0wk9l.png",
        smallImage: true,
    },
    {
        id: "step-3",
        title: "Step 3: Initiate Campaign Creation.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 3: To get started, head to the top right corner of the
                screen and click the "Create Campaign” button.
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1709658952/guides/crm-setup/step3_cwu6ol.png",
    },
    {
        id: "step-4",
        title: "Step 4:  Set Campaign Parameters.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 4: To create a campaign, follow these steps:
                <br />
                Enter your campaign title. <br />
                Input your campaign subject.
                <br />
                Choose your campaign channel (Either via SMS or Email).
                <br />
                Proceed to edit the content as needed by clicking on the “edit
                content” button.
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1709658952/guides/crm-setup/step4_om2w7u.png",
    },
    {
        id: "step-5",
        title: "Step 5: Customize Campaign Content.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 5: In the edit content section, customize your campaign
                content by adding headings, images, and more to suit your needs.
                Don't forget to click 'Save & Continue' at the top right to save
                your changes. Once you've finished editing, simply click the
                'Close' button, also located at the top right, to finalize your
                campaign.{" "}
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1709658952/guides/crm-setup/step5_s79n47.png",
    },
    {
        id: "step-6",
        title: "Step 6: Confirm Content Edits.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 6: If you are certain that all content edits have been
                completed, please click on the 'Yes, continue' button.
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1709658952/guides/crm-setup/step6_kny2lp.png",
    },
    {
        id: "step-7",
        title: "Step 7: Choose Recipients.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 7: Next, choose the recipients for your campaign, selecting
                who you want to receive this message. Once you've made your
                selections, click on "Send Now" to distribute your campaign.{" "}
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1709658951/guides/crm-setup/step7_y6rsjp.png",
    },
    {
        id: "step-8",
        title: "Step 8: Send Campaign.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 8: To proceed with sending out the campaign, please click
                on the "Yes, continue" button.
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1709658951/guides/crm-setup/step8_hlrgq9.png",
    },
];
