import { Col, Container, Nav, Row, Stack } from "react-bootstrap";
import { Logo } from "../assets";
import { Facebook, Instagram, Linkedin, Twitter } from "react-bootstrap-icons";

const Footer = () => {

  const socialLinks = {
    instagram: 'https://www.instagram.com/taojaahq/',
    twitter: 'https://twitter.com/taojaahq',
    facebook: 'https://www.facebook.com/Taojaa-100092380794963',
    linkedin: 'https://www.linkedin.com/company/taojaa/'
  }

  return (
    <footer className="bg-white">
      <Container className="py-3 pt-5">
        <Row className="gap--lg-4 my-4 mb-5 pt-5 justify-content-between">
          <Row className="mb-3 justify-content-between">
            <Col xs={5}>
              <a
                href={"/"}
                className="d-flex align-items-center mb-4 link-body-emphasis text-decoration-none"
              >
                <Logo />
              </a>
            </Col>

            <Col className="col-5"></Col>
          </Row>
          <Col lg={12}>
            <Row className="justify-content-between">
              <Col xs={12} xl={5} className="mb-3">
                <h5 className="fw-semibold mb-4">Solutions</h5>
                <Nav>
                  <Nav.Item className="mb-3 col-6 col-xl-6">
                    <Nav.Link href="/products/analytics-and-insights" className="p-0">
                      Analytics & Insights
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mb-3 col-6 col-xl-6">
                    <Nav.Link href="/products/payment-processing" className="p-0">
                      Payment Processing
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mb-3 col-6 col-xl-6">
                    <Nav.Link href="/products/order-and-sales-management" className="p-0">
                      Order & Sales Management
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mb-3 col-6 col-xl-6">
                    <Nav.Link href="/products/personal-storefront" className="p-0">
                      Personal Storefront
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mb-3 col-6 col-xl-6">
                    <Nav.Link href="/products/discount-and-coupons" className="p-0">
                      Discount & Coupons
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mb-3 col-6 col-xl-6">
                    <Nav.Link href="/products/inventory-management" className="p-0">
                      Inventory Management
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mb-3 col-6 col-xl-6">
                    <Nav.Link href="/products/invoicing-and-receipts" className="p-0">
                      Invoicing and Receipts
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>

              <Col xs={3} lg={2} className="mb-3">
                <h5 className="fw-semibold mb-4">Learn</h5>
                <Nav className="flex-column">
                  <Nav.Item className="mb-3">
                    <Nav.Link href="/company/about-us" className="p-0">
                      About Us
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mb-3">
                    <Nav.Link href="/pricing" className="p-0">
                      Pricing
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mb-3">
                    <Nav.Link href="/resources/faqs" className="p-0">
                      FAQs
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mb-3">
                    <Nav.Link href="/resources/how-to-guides" className="p-0">
                      Guides
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>

              <Col xs={2} lg={2} className="mb-3">
                <h5 className="fw-semibold mb-4">Company</h5>
                <Nav className="flex-column">
                  <Nav.Item className="mb-3">
                    <Nav.Link href="/terms" className="p-0">
                      Terms & Conditions
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mb-3">
                    <Nav.Link href="/privacy-policy" className="p-0">
                      Privacy Policy
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mb-3">
                    <Nav.Link href="#" className="p-0">
                      Careers
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>

              <Col xs={5} lg={3} className="mb-3">
                <h5 className="fw-semibold mb-4">Contact Us</h5>
                <Nav className="flex-column">
                  <Nav.Item className="mb-3">
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="justify-content-start"
                    >
                      <a target="_blank" rel="noreferrer" href={socialLinks.instagram}><Instagram size={21} /></a>
                      <a target="_blank" rel="noreferrer" href={socialLinks.twitter}><Twitter size={21} /></a>
                      <a target="_blank" rel="noreferrer" href={socialLinks.facebook}><Facebook size={21} /></a>
                      <a target="_blank" rel="noreferrer" href={socialLinks.linkedin}><Linkedin size={21} /></a>
                    </Stack>
                  </Nav.Item>
                  <Nav.Item className="mb-2">
                    <Nav.Link href="mailto:support@taojaa.com" className="p-0 fw-bold">
                      support@taojaa.com
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mb-3">
                    <Nav.Link href="tel:+2347036745467" className="p-0 fw-bold">+2347036745467</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
          </Col>
        </Row>

        <p className="text-center small">
          © {new Date().getFullYear()} <strong>Wearslot Solutions ltd.</strong>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
