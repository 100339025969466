import { useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Carousel,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import { Testimonials } from "../../data";

const TestimonialSlides = () => {
  const count = Testimonials.length - 1;
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    selectedIndex =
      selectedIndex < 0 || selectedIndex > count ? 0 : selectedIndex;
    setIndex(selectedIndex);
  };

  return (
    <section className="overflow-hidden bg-white py-5">
      <Container fluid="md" className={"p-0 py-lg-5"}>
        <Row className="justify-content-center text-center">
          <Col xl={6} lg={8} xs={10}>
            <h1 className="mb-2 fs-2 fw-bolder">
              Every user has a story to tell
            </h1>
            <p>See what businesses are saying about Taojaa.</p>
          </Col>
          <Col lg={9} xs={12} className="my-5">
            <Carousel
              fade
              variant="light"
              controls={false}
              indicators={false}
              interval={1000}
              activeIndex={index}
              onSelect={handleSelect}
            >
              {Testimonials.map((testimonial, key) => (
                <Carousel.Item key={key} className="bg-white rounded-4">
                  <Card
                    className="rounded-4 p-5 pb-3 testimonial"
                    border="0"
                    variant="light"
                    bg="white"
                  >
                    <Row className="align-items-center justify-content-between">
                      <Col
                        sm={4}
                        className="ms-4 my-4 position-relative d-none d-sm-block"
                      >
                        <div
                          className="bg-taoja-light-primary rounded-circle position-absolute z-0"
                          style={{
                            height: 150,
                            width: 150,
                            right: "-10px",
                            bottom: "-20px",
                          }}
                        ></div>
                        <div
                          className="bg-primary rounded-circle position-absolute z-0"
                          style={{
                            height: 150,
                            width: 150,
                            left: 10,
                            top: "-25px",
                          }}
                        ></div>
                        <Image
                          src={testimonial.img}
                          alt=""
                          className="object-fit-cover z-1 position-relative"
                          roundedCircle
                          height={200}
                          width={200}
                        />
                      </Col>
                      <Col xs={12} sm={7} className="text-start">
                        <Row>
                          <Col xs={3} className="d-block d-sm-none">
                            <Image
                              src={testimonial.img}
                              alt=""
                              className="object-fit-cover z-1 position-relative"
                              roundedCircle
                              width={'100%'}
                              style={{ aspectRatio: '1/1' }}
                            />
                          </Col>
                          <Col xs={9}>
                            <h5 className="text-primary-emphasis fs-4 mb-2">
                              {testimonial.name}
                            </h5>
                            <small>{testimonial.occupation}</small>
                          </Col>
                        </Row>
                        <p
                          className="mt-3"
                          dangerouslySetInnerHTML={{
                            __html: testimonial.comment,
                          }}
                        ></p>
                      </Col>
                    </Row>
                    <Col xs={2} className="ms-auto">
                      <ButtonGroup className="mb-2">
                        <Button
                          variant="link"
                          className="px-3"
                          onClick={() => handleSelect(index - 1)}
                        >
                          <ArrowLeft size={24} />
                        </Button>
                        <Button
                          variant="link"
                          className="px-3"
                          onClick={() => handleSelect(index + 1)}
                        >
                          <ArrowRight size={24} />
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Card>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TestimonialSlides;
