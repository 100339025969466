import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { HeroOne, ImgSide } from "../../assets";
import { AiFillThunderbolt } from "react-icons/ai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LuCheckCircle } from "react-icons/lu";

const Hero = () => {

    const router = useNavigate();
    const [email, setEmail] = useState('');

    const getStarted = () => {

        if (email !== '') {
            localStorage.setItem('email', email);
        }

        router('/auth/create-account');
    }

    return (
        <section className="hero overflow-hidden mb-3 py-lg-5 pb-sm-5">
            <Container className={"pb-5"}>
                <Row className="justify-content-around">
                    <Col lg={7} xxl={7} className="text-side text-center text-lg-start px-2 px-lg-0">
                        <div className="grow-tag p-2 mb-4 mx-auto mx-lg-0">
                            <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <p className="ms-2">Build amazing e-commerce experience with Taojaa</p>
                            </div>
                        </div>
                        <h1 className="mb-1 hero-h1 display-5 fw-bolder" data-aos="fade-down" data-aos-duration="500" style={{ color: "#4A4455" }}>
                            Simplifying <span className="text-primary">Digital Commerce</span>
                        </h1>
                        <h1 className="mb-1 hero-h1 display-5 fw-bolder" data-aos="fade-down" data-aos-duration="500" style={{ color: "#4A4455" }}>
                            for <span className="text-primary">Africans</span> across the globe.
                        </h1>
                        <p className="hero-para my-3" data-aos="fade-up" data-aos-duration="500">
                            Power your online store with Taojaa tools and build your dream e-commerce experience for your customers and clients.
                        </p>
                        <Col lg={9}>
                            <div className="getStarted-btns d-flex">
                                <Form.Control
                                    className="email-input p-3 "
                                    placeholder="Enter your email"
                                    aria-label="Email Address"
                                    aria-describedby="signup-btn"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Button variant="primary" id="signup-btn" className=" ms-3 pt-3 px-2 fw-bold" onClick={getStarted}>
                                    Get Started
                                </Button>
                            </div>
                        </Col>
                    </Col>
                    <Col lg={5} xxl={5} className="img-side px-4">
                        <div className="img-div">
                            <div className="text-center">
                                <img src={`https://res.cloudinary.com/dp7quxpsq/image/upload/v1721815492/Authapp-Images/HERO_SECTION_copy_o0650c.png`} alt="hero" className="heroOne img-fluid ms-lg-4" data-aos-duration="500" data-aos="fade-left" />
                            </div>
                            <div className="grow-tag tag1 p-2 p-lg-3 mb-4">
                                <div className="d-flex align-items-center">
                                    <AiFillThunderbolt />
                                    <p className="ms-2">Create an online store in 2 mins</p>
                                </div>
                            </div>
                            <div className="grow-tag tag2 p-2 p-lg-3 mb-4">
                                <div className="d-flex align-items-center">
                                    <LuCheckCircle />
                                    <p className="ms-2">Collect payments with SMART tools</p>
                                </div>
                            </div>
                            {/* <div className="tag3" data-aos="fade-right">
                                <img src={ImgSide} alt="hero svg" className="img-fluid" />
                            </div> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Hero;
