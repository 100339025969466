import { useEffect } from "react";
import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import { Outlet } from "react-router-dom";
import FootBanner from "./FootBanner.jsx";
import Aos from "aos";
import 'aos/dist/aos.css';

const AppLayout = () => {

    useEffect(() => {
        Aos.init();
    }, []);

    return (
        <>
            <Header />
            <Outlet />
            <FootBanner />
            <Footer />
        </>
    );
};

export default AppLayout;
