import Help from "../Help";
import React from "react";

const HowToAddDeliverySetup = () => {
    return (
        <div className="container mb-5">
            <div className="row">
                <div className="col-lg-3 desktop relative">
                    <ul
                        className="vn-guides-sidebar border-right"
                        id="vn-guides-sidebar"
                    >
                        <li className="text-muted vn-item active mb-3">
                            <a href="#intro" className="vc-click">
                                <b>Setting up delivery options.</b>
                            </a>
                        </li>
                        {deliverySetupSteps.map((step) => (
                            <li
                                key={step.id}
                                className="text-muted vn-item mb-3"
                            >
                                <a href={`#${step.id}`} className="vc-click">
                                    {step.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="col-lg-7">
                    <div id="intro" className="vn-guides pt-5"
                     style={{ marginBottom: "40px"}}
                    >
                        <h1 className="">
                            Setup delivery options for checkout & fulfilment.
                        </h1>
                    </div>

                    {deliverySetupSteps.map((step) => (
                        <div
                        style={{border: "0px solid green", marginBottom: "50px"}}
                        key={step.id} id={step.id} className="mb-20 vn-guides">
                            {step.description}
                            <p className="mt-3 mb-6 vn-guides-desc">
                                <img
                                    src={step.image}
                                    alt="" style={{ width: "100%" }} />
                            </p>
                        </div>
                    ))}
                    <Help />
                </div>
            </div>
        </div>
    );
};

export default HowToAddDeliverySetup;

const deliverySetupSteps = [
    {
        id: "step-1",
        title: "Step 1: Log in to Taojaa store manager dashboard.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 1: Log in to your Taojaa store manager dashboard via{" "}
                <a href="https://www.taojaa.com">www.taojaa.com</a>, click on
                "Store Settings" located on the dashboard.
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1708616057/guides/delivery-setup/Step1_aebytt.png",
    },
    {
        id: "step-2",
        title: "Step 2: Navigate to Delivery Setup.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 2: Navigate to "Delivery Setup" in the dropdown menu
                located under "Store Settings.
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1708615840/guides/delivery-setup/Step2_j54ko1.png",
    },
    {
        id: "step-3",
        title: "Step 3: Select delivery provider.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 3: To begin setting up your delivery, Click on the provider
                you want to connect your store with, for example, "Kwik".
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1708615878/guides/delivery-setup/Step3_deqiel.png",
    },
    {
        id: "step-4",
        title: "Step 4: Connect or create delivery provider account.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 4: Fill the form on the provider setup page to connect your
                existing Kwik account or head to Kwik.delivery website to
                request a new account. Alternatively, you can enable Taojaa's
                provider settings for an easy set-up.
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1708615879/guides/delivery-setup/Step4_wvdcgy.png",
    },
    {
        id: "step-5",
        title: "Step 5: Select or add pickup location.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 5: Select a default pickup location from the list, if you
                haven’t added a pick up station, click on "Add Pickup Station"
                to add a pickup location for the delivery provider.
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1708615924/guides/delivery-setup/Step5_rc7xmu.png",
    },
    {
        id: "step-6",
        title: "Step 6: Save provider setup.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 6: Once you completed the setup form Click on “Save” to
                save provider setup. After saving click on the back arrow to go
                back to the delivery setup section
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1708615925/guides/delivery-setup/Step6_spg4dy.png",
    },
    {
        id: "step-7",
        title: "Step 7: Create delivery region.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 7: Once you've connected your service provider, proceed to
                create a region by clicking on the "Create Region" button.
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1708615925/guides/delivery-setup/Step7_xusppa.png",
    },
    {
        id: "step-8",
        title: "Step 8: Define delivery zones.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 8: Enter your preferred name, choose the zones where you
                would like to deliver to, and click "Save" to proceed.
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1708615925/guides/delivery-setup/Step8_wrjnbv.png",
    },
    {
        id: "step-9",
        title: "Step 9: Add delivery rates.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 9: After creating your region, the next step is to add your
                delivery rates. Simply click on the "Add Rates" button.
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1708615925/guides/delivery-setup/Step9_bkjxdh.png",
    },
    {
        id: "step-10",
        title: "Step 10: Set up delivery rates.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Step 10: Fill in your preferred name, description, choose the
                connected delivery provider, opt for either the provided
                delivery rate or input your custom rate (In the estimated
                delivery rate section), then click "Save" to continue.
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1708615926/guides/delivery-setup/Step10_eu0l4k.png",
    },
    {
        id: "step-11",
        title: "Step 11: Done, Delivery setup complete.",
        description: (
            <p className="mt-2 mb-2 vn-guides-desc">
                Done: By following these steps, you have successfully set up
                your delivery preferences in the delivery setup section.
            </p>
        ),
        image: "https://res.cloudinary.com/dahdlotat/image/upload/v1708615926/guides/delivery-setup/Step11_lmei3e.png",
    },
];
