import PricingCards, { choosePlan } from "../../components/PricingCard.jsx";
import { Button, Col, Container, Table } from "react-bootstrap";
import { BillingCycles, PricingData, PricingFeatures } from "../../data";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

const Pricing = () => {
  const navigate = useNavigate();

  const [billingCycle, setBillingCycle] = useState(BillingCycles[0].key);

  return (
    <>
      <Container fluid>
        <div className="mx-md-5 my-4">
          <PricingCards
            billingCycle={billingCycle}
            handleChange={setBillingCycle}
          />
        </div>

        <div className="bg-white">
          <Container className="py-5 mt-5">
            <Col lg={5} className="py-5">
              <h1 className="mb-4 fs-1 fw-bolder">Compare plan & features</h1>
              <p className="col-8">
                Quick rundown of our different plans. Pick the best one for your needs
              </p>
            </Col>
            <Table responsive borderless>
              <thead>
                <tr>
                  <th className="w-25"></th>
                  {PricingData.map((pricing, key) => (
                    <th key={key} scope="col" className="text-center">
                      <h3>{pricing.title}</h3>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {PricingFeatures?.map((feature, index) => (
                  <Fragment key={index}>
                    <tr>
                      <th className="h5 d-block ps-2 mb-4 mt-5">
                        {feature.title}
                      </th>
                    </tr>
                    {feature?.subs?.map((sub, index) => (
                      <tr className="text-center" key={index}>
                        <th scope="row" className="fw-normal py-3 text-start">
                          <h6>{sub.name}</h6>
                          <small>{sub?.desc}</small>
                        </th>
                        <td className="text-center" valign="middle">{sub.stats.Professional}</td>
                        <td className="text-center" valign="middle">{sub.stats.Premium}</td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
              <tfoot>
              <tr>
                  <th className="w-25"></th>
                  {PricingData.map((pricing, key) => (
                    <th key={key} scope="col" className="text-center">
                      <Button
                        style={{whiteSpace: "nowrap"}}
                        variant={pricing.btnType}
                        className="fw-bold mt-3"
                        onClick={() =>
                          choosePlan(`Choose ${pricing.title}`, navigate)
                        }
                      >
                        Get Started
                      </Button>
                    </th>
                  ))}
                </tr>
              </tfoot>
            </Table>
          </Container>
        </div>
      </Container>
    </>
  );
};

export default Pricing;
