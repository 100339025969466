import React from 'react'
import { Card } from 'react-bootstrap'

export default function CardImage({ imgSrc }) {
  return (
    <div>
      <Card
        style={{
          borderRadius: '15px',
          maxWidth: 580,
        }}
      >
        <Card.Img variant='top' src={imgSrc} />
      </Card>
    </div>
  )
}
