import Modal from '../Modal';
import React, { useState } from 'react'
import { ReactComponent as Forgotpasswordsvg } from '../assets/Forgotpasswordsvg.svg';
import { ReactComponent as ForgotPasswordMobileImage } from '../assets/ForgotPasswordMobileImage.svg';
import { BsEnvelope } from 'react-icons/bs'
import Logo from '../Shared/Logo';
import axios from 'axios';
import ValidateSubmit from 'wearslot-dev-utils';
import { messageHandler } from '../../bundles/app';
import { Link } from 'react-router-dom';

export const ModalBody = () => {
    return (
        <div className='d-flex flex-column align-items-center justify-content-center tj-body-forget mt-2'>
            <span>A link has been sent to your email to complete password reset.</span>
        </div>
    )
}

const ForgotPassword = () => {

    document.title = 'Forgot Password - Taojaa';

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false)
    const [user, setUser] = useState({
        email: ''
    })

    const handleInput = (e) => {
        const value = e.target.value
        const field = e.target.name

        setUser({ ...user, [field]: value })
    }

    const handleSubmit = (e) =>
    {
        if (ValidateSubmit(false, true))
        {
            e.preventDefault()
            setLoading(true);

            axios.post('/account/forget-password', user).then(response =>
            {
                if (response.data.success)
                {
                    messageHandler('success', response.data.message)
                    setShow(true)
                }
            }).catch(error =>
            {
                setLoading(false)
                if (error.response)
                {
                    messageHandler("error", error.response.data.message);
                } else
                {
                    messageHandler("error", 'Unable to process request. Please try again');
                }
            })
        }
    }

    return (
        <div className='col-md-12'>
            <div className='tj-form-response'>
                <p id='tj-resp-Message'></p>
            </div>
            <div className='row tj-everything'>
                <div className='col-md-6'>
                    <Logo />
                    <div className='d-flex justify-content-center mt-4'>
                        <div className='tj-box'>
                            <div className='mobile mb-2 text-center-mobile'>
                                <ForgotPasswordMobileImage />
                            </div>
                            <div className='text-center-mobile'>
                                <h1 className='mb-1'>Forget Password</h1>
                                <h2>Please input the email you use to create the account</h2>
                            </div>
                            <form>
                                <div className="tj-form mb-3 position-relative">
                                    <div className='form-group'>
                                        <label for="Email" className="form-label">Your Email</label>
                                        <input
                                            type={"email"}
                                            name='email'
                                            value={user.email}
                                            onChange={e => handleInput(e)}
                                            validatefield='true'
                                            validationoutput='#emailerror'
                                            validationmessage='Email cannot be empty!!'
                                            className="form-control mb-3"
                                            id="Email1"
                                            aria-describedby="emailHelp"
                                            placeholder='Example@gmail.com' />
                                        <span id='emailerror'></span>
                                    </div>
                                </div>
                            </form>
                            <button className={`tj-box1 mt-4 ${loading ? 'button-disabled' : ''}`} disabled={loading} onClick={handleSubmit}>
                                {loading ? <lord-icon
                                    src="https://cdn.lordicon.com/ymrqtsej.json"
                                    trigger="loop"
                                    colors="primary:#ffffff"
                                    style={{ width: "50px", height: "50px" }}>
                                </lord-icon> : 'Proceed'}
                            </button>
                            <div className='text-center mt-3'>
                                <Link to={'/auth/login'} style={{ color: '#8A50FC', textDecoration: 'none', fontSize: 12, fontWeight: 500 }}> Back to Login</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 desktop'>
                    <Forgotpasswordsvg className='tj-right' />
                </div>
                <Modal
                    show={show}
                    onClose={() => setShow(false)}
                    icon={<BsEnvelope size={26} />}
                    body={<ModalBody />}
                />
            </div>
        </div>
    )
}

export default ForgotPassword