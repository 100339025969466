import React from 'react'
import { Button, Col } from 'react-bootstrap'
import { ArrowRight } from 'react-bootstrap-icons'

export default function UniqueCard({ imgSrc, title, desc, btnUrl }) {
  return (
    <>
      <Col lg={12} className='pb-3 benefit-card rounded-5'>
        <img src={imgSrc} alt={title} />
        <Col className='px-3 pt-3'>
          <h4 className='text-white'>{title}</h4>
          <Col lg={12}>
            <p className='text-white'>{desc}</p>
          </Col>
          <Button
            className='text-white card-button'
            variant=''
            href={"/auth/create-account"}
          >
            {btnUrl}
            <ArrowRight className='fw-bold' />
          </Button>
        </Col>
      </Col>
    </>
  )
}
