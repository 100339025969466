import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const AnalyticsAndInsights = ({ data }) => {
    return (
        <section style={{backgroundColor: '#F9FAFB'}}>
            <Container className='py-5'>
                <Col lg={9}>
                    <h1 className='fw-bolder section-title' data-aos="fade-down" data-aos-duration="1000">{data.analytics.heading}</h1>
                    <Col lg={7} data-aos="fade-right" data-aos-duration="1000">
                        <p style={{ fontSize: 20 }}>{data.analytics.subheading}</p>
                    </Col>
                </Col>
                <Row>
                    {data.analytics.features.map((feature, index) => 
                        <Col lg={6} className='p-1 mb-xs-3' data-aos={`${((index % 2) > 0) ? 'fade-right' : 'fade-left'}`} data-aos-duration="1000" key={index}>
                            <Col lg={12} className='shadow-sm bg-white rounded-2 bordered p-4 px-md-5 px-xs-2 h-100'>
                                <Col className='text-center'>
                                    <img src={feature.icon} alt={feature.name} />
                                </Col>
                                <h5>{feature.name}</h5>
                                <p>{feature.caption}</p>
                            </Col>
                        </Col>
                    )}
                </Row>
            </Container>
        </section>
    )
}

export default AnalyticsAndInsights