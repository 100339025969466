import React from 'react'
import UniqueCard from '../../components/unique/Card'
import { Col, Container, Row } from 'react-bootstrap'
import CardImage from '../../components/unique/CardImage'
import img5 from '../../components/assets/Frame-page2-6.png'
import imag2 from '../../components/assets/pexels-mart-page2-2.png'
import img3 from '../../components/assets/pexels-mart-page2-3.png'
import img9 from '../../components/assets/pexels-mart-page2-4.png'
import img4 from '../../components/assets/pexels-mart-page2-5.png'
import img8 from '../../components/assets/image27-page2-1.png'
import CardText2 from '../../components/unique/CardText2'
import HeroCard from '../../components/unique/HeroCard'

export default function UniquePage2() {
  return (
    <>
      <HeroCard
        title={`Boost your business with Taojaa.`}
        desp={`Effortless marketing is now at your fingertips. Automate your marketing strategies and promote your business across various platforms: Facebook, Instagram, TikTok, Google, and beyond. With Taojaa, marketing is made simple.`}
      />

      <Container className='g2 mx-auto'>
        <Row className='align-items-center '>
          <section
            className='box-section align-items-center'
          >
            <Col md={6} sm={12} data-aos='fade-up' data-aos-duration='1000'>
              <CardImage imgSrc={img8} />
            </Col>
            <Col className='order-md-first' md={6} sm={12} data-aos='fade-up' data-aos-duration='1000'>
              <CardText2
                btnUrl={`Get Started`}
                desc={`: Dive into detailed analytics and insights to understand your business better. Gain valuable data on customer behavior, track sales, and make informed decisions to boost your business`}
                title={`Analytics and Insights`}
              />
            </Col>
          </section>
          <section
            className='box-section align-items-center'
          >
            <Col md={6} sm={12} className='mb-3' data-aos='fade-up' data-aos-duration='1000'>
              <UniqueCard
                btnUrl={`Get Started`}
                desc={`Run sales and promotional campaigns effortlessly to attract more customers. Also set up discounts, promo codes, and special offers to boost sales.`}
                title={`Promotions and Sales`}
                imgSrc={imag2}
              />
            </Col>
            <Col md={6} sm={12} className='mb-3' data-aos='fade-up' data-aos-duration='1000'>
              <UniqueCard
                btnUrl={`Get Started`}
                desc={`Improve your online visibility with Taojaa's SEO features. Optimize your product listings and increase the chances of being found by potential customers.`}
                title={`SEO Features`}
                imgSrc={img3}
              />
            </Col>
          </section>
          <section
            className='box-section align-items-center'
          >
            <Col className='order1' md={6} sm={12} data-aos='fade-up' data-aos-duration='1000'>
              <CardText2
                btnUrl={`Get Started`}
                desc={` Obtain a consolidated view of your business performance across various channels, aiding in precise strategy formulation.`}
                title={`Smart Reporting`}
              />
            </Col>
            <Col md={6} sm={12} data-aos='fade-up' data-aos-duration='1000'>
              <CardImage imgSrc={img9} />
            </Col>
          </section>
          <section
            className='box-section align-items-center'
          >
            <Col md={6} sm={12} className='mb-3' data-aos='fade-up' data-aos-duration='1000'>
              <UniqueCard
                btnUrl={`Get Started`}
                desc={` Expand your customer database effortlessly, capturing valuable insights from every shopping avenue for effective retargeting and sustained customer loyalty.`}
                title={`Customer Growth`}
                imgSrc={img4}
              />
            </Col>
            <Col md={6} sm={12} className='mb-3' data-aos='fade-up' data-aos-duration='1000'>
              <UniqueCard
                btnUrl={`Get Started`}
                desc={` Easily sell your products across various platforms like your online store, Facebook, Instagram, Google, Pinterest, and TikTok.`}
                title={`Multichannel Selling`}
                imgSrc={img5}
              />
            </Col>
          </section>
        </Row>
      </Container>
    </>
  )
}
