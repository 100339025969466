import React from 'react'
import { Badge, Button, Col, Container, Row } from 'react-bootstrap'
import { PlayBtn } from 'react-bootstrap-icons'
import { CustomerServiceImg, FinanceImg, InsightImg, SaleImg, StorefrontImg } from '../../assets'


const Hero = ({ data }) => {

    return (
        <section>
            <Container className='text-center'>
                <Col lg={12} className='mt-5 mb-3'>
                    {data.hero.header}
                </Col>
                <Row className={'justify-content-center'} data-aos="fade-up" data-aos-duration="1000">
                    <Col lg={9} className='rounded-5 py-4 px-4 mb-2 bg-light position-relative'>
                        <div className='category-banner' style={{backgroundImage: `url("${data.hero.banner}")`, backgroundSize: 'cover'}}></div>
                        <Badge bg='white' className='feature-badge storefront shadow py-sm-1 px-sm-2 rounded-4 position-absolute border border-5 border-light' data-aos="fade-right" data-aos-duration="1000">
                            <img className='feature-img' src={StorefrontImg} alt="Free storefront/website"/>
                            <span className='text-dark ms-2'>Free Storefront/Website</span>
                        </Badge>

                        <Badge bg='white' className='feature-badge finance shadow p-2 px-3 rounded-4 position-absolute border border-5 border-light' data-aos="fade-right" data-aos-duration="1000">
                            <img className='feature-img' src={FinanceImg} alt="Manage Finances"/>
                            <span className='text-dark ms-2'>Manage Finances</span>
                        </Badge>

                        <Badge bg='white' className='feature-badge sales shadow p-2 px-3 rounded-4 position-absolute border border-5 border-light' data-aos="fade-up" data-aos-duration="1000">
                            <img className='feature-img' src={SaleImg} alt="Boost Sales"/>
                            <span className='text-dark ms-2'>Boost Sales</span>
                        </Badge>

                        <Badge bg='white' className='feature-badge insight shadow p-2 px-3 rounded-4 position-absolute border border-5 border-light' data-aos="fade-left" data-aos-duration="1000">
                            <img className='feature-img' src={InsightImg} alt="Gain Insights"/>
                            <span className='text-dark ms-2'>Gain Insights</span>
                        </Badge>

                        <Badge bg='white' className='feature-badge support shadow p-2 px-3 rounded-4 position-absolute border border-5 border-light' data-aos="fade-left" data-aos-duration="1000">
                            <img className='feature-img' src={CustomerServiceImg} alt="Customer Support"/>
                            <span className='text-dark ms-2'>Customer Support</span>
                        </Badge>
                    </Col>
                    <Col lg={6} className='mt-5' data-aos="fade-up" data-aos-duration="1000">
                        <Button href='/auth/create-account' variant='outline-primary' className='me-3 rounded-3 text-black'>
                            Get Started
                        </Button>
                        <Button className='rounded-3 btn-primary'>
                            <PlayBtn/> View Demo
                        </Button>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Hero