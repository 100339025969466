import {Accordion} from "react-bootstrap";
import PropTypes from "prop-types";

const FaqTab = ({faqs}) => {
    return (

        faqs.map((faq, index) => (
            <Accordion key={index}>
                <Accordion.Item eventKey={faq.q}>
                    <Accordion.Header className="gap-5">{faq.q}</Accordion.Header>
                    <Accordion.Body>
                        {faq.a}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        ))
    );
};


FaqTab.propTypes = {
    faqs: PropTypes.arrayOf(PropTypes.shape({q: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), a: PropTypes.oneOfType([PropTypes.string, PropTypes.node])}))
};

export default FaqTab;

