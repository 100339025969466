import React from 'react'
import Help from '../Help'

import InvoiceMenu from "../../../../assets/InvoiceMenu.png"
import CreateInvoiceButton from "../../../../assets/create-invoice-button.png"
import InvoiceCustomerDueDatePayment from "../../../../assets/InvoiceCustomerDueDatePayment.png"
import BillingAddress from "../../../../assets/InvoiceBillingAddress.png"
import ShippingAddress from "../../../../assets/InvoiceShippingAddress.png"
import AdditionalNotes from "../../../../assets/invoice-additional-notes.png"
import AddItemButton from "../../../../assets/AddItemButton.png"
import InvoiceItem from "../../../../assets/InvoiceItem.png"
import PaymentMethods from "../../../../assets/invoice-payment-methods.png"


const HowToCreateInvoices = () => {
    return (
        <div className='container mb-5'>
            <div className='row'>
                <div className='col-lg-3 desktop relative'>
                    <ul className="vn-guides-sidebar border-right" id="vn-guides-sidebar">
                        <li className="text-muted vn-item active mb-3 fw-bold"><a href="#intro" className="vc-click">Create invoice</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-1" className="vc-click">Step 1: Invoice  Receipts</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-2" className="vc-click">Step 2: Create Invoice button</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-3" className="vc-click">Step 3: Customer, Due Date & Payment Status</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-4" className="vc-click">Step 4: Billing Address</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-5" className="vc-click">Step 5: Shipping Address</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-6" className="vc-click">Step 6: Invoice Items.</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-7" className="vc-click">Step 7: Payment Method.</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-8" className="vc-click">Step 8: Additional Note.</a></li>
                        <li className="text-muted vn-item mb-3"><a href="#step-9" className="vc-click">Step 9: Review & Save.</a></li>
                    </ul>
                </div>
                <div className="col-lg-7">
                    <div id="intro" className="vn-guides pt-5">
                        <h1 className="">Create invoice.</h1>
                    </div>

                    <div id="step-1" className="vn-guides">
                        <p className="mt-2 mb-2 vn-guides-desc">
                            Step 1: Access your dashboard and click on Invoice and Receipts on the sidebar menu
                        </p>

                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={InvoiceMenu} alt="" />
                        </p>
                    </div>

                    <div id="step-2" className="vn-guides">
                        <p className="vn-guides-desc mb-2">
                            Step 2: Click on the "Create Invoice" button
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={CreateInvoiceButton} alt="" style={{ width: "100%" }} />
                        </p>
                    </div>

                    <div id="step-2" className="vn-guides">
                        <p className="vn-guides-desc mb-2">
                            Step 3: Customer: select the customer that you need to create and invoice for. Fill in the due date and payment status.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={InvoiceCustomerDueDatePayment} alt="" style={{ width: "100%" }} />
                        </p>
                    </div>

                    <div id="step-4" className="vn-guides">
                        <p className="vn-guides-desc mb-2">
                            Step 4: Billing Address: Fill in the customer's billing address.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={BillingAddress} alt="" style={{ width: window.innerWidth <= 480 ? '100%' : 'auto' }} />
                        </p>
                    </div>

                    <div id="step-5" className="vn-guides">
                        <p className="mb-2 vn-guides-desc">
                            Step 5: Shipping Address: Fill in the customer’s shipping address.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={ShippingAddress} alt="" style={{ width: window.innerWidth <= 480 ? '100%' : 'auto' }} />
                        </p>
                    </div>

                    <div id="step-6" className="vn-guides">
                        <p className="mb-2 vn-guides-desc">
                            Step 6: Items: Click on add items, select the product that you are creating an invoice for then input the rate (price) and Input the quantity. The amount and subtotal is automatically set when you fill the price and quantity. So skip to the discount and shipping option and fill accordingly.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={AddItemButton} alt="" style={{ width: "100%" }} />
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={InvoiceItem} alt="" style={{ width: "100%" }} />
                        </p>
                    </div>
                    <div id="step-7" className="vn-guides">
                        <p className="mb-2 vn-guides-desc">
                            Step 7: Select payment method among the three options; card, cash payment, and bank transfer.
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={PaymentMethods} alt="" />
                        </p>
                    </div>
                    <div id="step-8" className="vn-guides">
                        <p className="mb-2 vn-guides-desc">
                            Step 8: Add a note
                        </p>
                        <p className="mt-4 mb-2 vn-guides-desc">
                            <img src={AdditionalNotes} alt="" style={{ width: "100%" }} />
                        </p>
                    </div>
                    <div id="step-9" className="vn-guides">
                        <p className="mb-2 vn-guides-desc">
                            Step 9: Go through all the steps above for correctness and click on save invoice.
                        </p>
                    </div>
                    <Help />
                </div>
            </div>
        </div>
    )
}

export default HowToCreateInvoices